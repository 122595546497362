import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form, Dropdown } from 'react-bootstrap'



import ModalServiciosExtras from './ModalServiciosExtras'


import ModalRegistroPagoExtrasCliente from './ModalRegistroPagoExtrasCliente'

import ModalRegistroPagoIgualaCliente from './ModalRegistroPagoIgualaCliente'

import ModalRegistroPagoIguala from './ModalRegistroPagoIguala'

import ModalVisualizarRegistroPago from './ModalVisualizarRegistroPago'

import { formatToTwoDecimals } from '../../services/funcionesPublicas'




import ModalRegistroPagoServiciosDelPeriodo from './ModalRegistroPagoServiciosDelPeriodo'


import ModalServiciosExtrasPeriodoPorBoutique from './ModalServiciosExtrasPeriodoPorBoutique'


const CobranzaServicios = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 20
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`cobros_servicios/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);


 




  const [ periodo_seleccionado, setPeriodo_seleccionado ] = useState<any>(null)
  const [ cobros_del_periodo_seleccionado, setCobros_del_periodo_seleccionado ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarCobros_del_periodo_seleccionado = async(periodo:any)=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`cobranza/${periodo.uuid}`)
      setCobros_del_periodo_seleccionado(response.data)
      setPeriodo_seleccionado(periodo)
      setSeEnvio(false)
    }
    catch(e){
      setPeriodo_seleccionado(null)
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  // React.useEffect(() => {
  //   cargarCobros_del_periodo_seleccionado()
  // }, []);


  const [ boutique_seleccionada, setBoutiqueSeleccionada ] = useState<any>(null)
  const [ tipo_ingreso_seleccionado, setTipoIngresoSeleccionado ] = useState<any>("")
  const [ show_ModalRegistroPagoIguala, setModalRegistroPagoIguala ] = useState<boolean>(false)
  const handleShow_ModalRegistroPagoIguala = (boutique:any,tipo_ingreso:string) => {
    setTipoIngresoSeleccionado(tipo_ingreso)
    setBoutiqueSeleccionada(boutique)
    setModalRegistroPagoIguala(true)
  }
  const handleClose_ModalRegistroPagoIguala = () => {
    cargarCobros_del_periodo_seleccionado(periodo_seleccionado)
    setModalRegistroPagoIguala(false)
  }

     




  const [ show_ModalVisualizarRegistroPago, setModalVisualizarRegistroPago ] = useState<boolean>(false)
  const handleShow_ModalVisualizarRegistroPago = (boutique:any,tipo_servicio:string) => {
    setTipoIngresoSeleccionado(tipo_servicio)
    setBoutiqueSeleccionada(boutique)
    setModalVisualizarRegistroPago(true)
  }
  const handleClose_ModalVisualizarRegistroPago = () => {
    cargarCobros_del_periodo_seleccionado(periodo_seleccionado)
    setModalVisualizarRegistroPago(false)
  }


const sumarMontos = (arreglo:any, tipoSuma:string) => {
  let total = 0
  for(let boutique of arreglo){
      if(tipoSuma==='MANTENIMIENTO'){
        if(boutique.tiene_servicios_mantenimiento){
          if(boutique.pago_iguala_mantenimiento){
            total += parseFloat(boutique.pago_iguala_mantenimiento.monto)
          }
          else{
            total += parseFloat(boutique.monto_iguala_mantenimiento)
          }
        }
      }
      if(tipoSuma==='CONSERVACION'){
        if(boutique.tiene_servicios_conservacion){
          if(boutique.pago_iguala_conservacion){
            total += parseFloat(boutique.pago_iguala_conservacion.monto)
          }
          else{
            total += parseFloat(boutique.monto_iguala_conservacion)
          }
        }
      }
      if(tipoSuma==='LIMPIEZA'){
        if(boutique.tiene_servicios_limpieza){
          if(boutique.pago_iguala_limpieza){
            total += parseFloat(boutique.pago_iguala_limpieza.monto)
          }
          else{
            total += parseFloat(boutique.monto_iguala_limpieza)
          }
        }
      }
  }
  return total;
}


const sumarMontosExtras = (arreglo:any, tipoSuma:string) => {
  let total = 0
  for(let boutique of arreglo){
      if(tipoSuma==='MANTENIMIENTO'){
        if(boutique.tiene_servicios_mantenimiento_extra){
          if(boutique.pago_iguala_mantenimiento_extra){
            total += parseFloat(boutique.pago_iguala_mantenimiento.monto)
          }
          else{
            total += parseFloat(boutique.monto_extras_mantenimiento)
          }
        }
      }
      if(tipoSuma==='CONSERVACION'){
        if(boutique.tiene_servicios_conservacion_extra){
          if(boutique.pago_iguala_conservacion_extra){
            total += parseFloat(boutique.pago_iguala_conservacion.monto)
          }
          else{
            total += parseFloat(boutique.monto_extras_conservacion)
          }
        }
      }
      if(tipoSuma==='LIMPIEZA'){
        if(boutique.tiene_servicios_limpieza_extra){
          if(boutique.pago_iguala_limpieza_extra){
            total += parseFloat(boutique.pago_iguala_limpieza.monto)
          }
          else{
            total += parseFloat(boutique.monto_extras_limpieza)
          }
        }
      }
  }
  return total;
}


const tieneServicios = (arreglo:any, tipoServicio:any) => {
  if (!['MANTENIMIENTO', 'CONSERVACION', 'LIMPIEZA'].includes(tipoServicio)) {
    throw new Error('Tipo de servicio no válido');
  }

  let propiedadServicio : string;
  switch (tipoServicio) {
    case 'MANTENIMIENTO':
      propiedadServicio = 'tiene_servicios_mantenimiento';
      break;
    case 'CONSERVACION':
      propiedadServicio = 'tiene_servicios_conservacion';
      break;
    case 'LIMPIEZA':
      propiedadServicio = 'tiene_servicios_limpieza';
      break;
  }

  return arreglo.some((item:any) => item[propiedadServicio]);
}


const tieneServiciosExtras = (arreglo:any, tipoServicio:any) => {
  if (!['MANTENIMIENTO', 'CONSERVACION', 'LIMPIEZA'].includes(tipoServicio)) {
    throw new Error('Tipo de servicio no válido');
  }

  let propiedadServicio : string;
  switch (tipoServicio) {
    case 'MANTENIMIENTO':
      propiedadServicio = 'tiene_servicios_mantenimiento_extra';
      break;
    case 'CONSERVACION':
      propiedadServicio = 'tiene_servicios_conservacion_extra';
      break;
    case 'LIMPIEZA':
      propiedadServicio = 'tiene_servicios_limpieza_extra';
      break;
  }

  return arreglo.some((item:any) => item[propiedadServicio]);
}



  const [ cliente_seleccionado, setClienteSeleccionado ] = useState<any>(null)
  const [ show_ModalServiciosExtras, setModalServiciosExtras ] = useState<boolean>(false)
  const handleShow_ModalServiciosExtras = () => {
    setModalServiciosExtras(true)
  }
  const handleClose_ModalServiciosExtras = () => {
    setModalServiciosExtras(false)
  }





  const [ show_ModalRegistroPagoIgualaCliente, setModalRegistroPagoIgualaCliente ] = useState<boolean>(false)
  const handleShow_ModalRegistroPagoIgualaCliente = (cliente:any,tipo_servicio:string) => {
    setTipoIngresoSeleccionado(tipo_servicio)
    setClienteSeleccionado(cliente)
    setModalRegistroPagoIgualaCliente(true)
  }
  const handleClose_ModalRegistroPagoIgualaCliente = () => {
    // cargarCobros_del_periodo_seleccionado(periodo_seleccionado)
    setModalRegistroPagoIgualaCliente(false)
  }




  const [ show_ModalRegistroPagoExtrasCliente, setModalRegistroPagoExtrasCliente ] = useState<boolean>(false)
  const handleShow_ModalRegistroPagoExtrasCliente = (cliente:any,tipo_servicio:string) => {
    setTipoIngresoSeleccionado(tipo_servicio)
    setClienteSeleccionado(cliente)
    setModalRegistroPagoExtrasCliente(true)
  }
  const handleClose_ModalRegistroPagoExtrasCliente = () => {
    setModalRegistroPagoExtrasCliente(false)
  }






  const [ show_ModalRegistroPagoServiciosDelPeriodo, setModalRegistroPagoServiciosDelPeriodo ] = useState<boolean>(false)
  const handleShow_ModalRegistroPagoServiciosDelPeriodo = (boutique:any,tipo_servicio:string) => {
    setTipoIngresoSeleccionado(tipo_servicio)
    setBoutiqueSeleccionada(boutique)
    setModalRegistroPagoServiciosDelPeriodo(true)
  }
  const handleClose_ModalRegistroPagoServiciosDelPeriodo = () => {
    setModalRegistroPagoServiciosDelPeriodo(false)
  }

  const handleClose_ModalRegistroPagoServiciosDelPeriodoLoagind = () => {
    cargarCobros_del_periodo_seleccionado(periodo_seleccionado)
    setModalRegistroPagoServiciosDelPeriodo(false)
  }






  const [ show_ModalServiciosExtrasPeriodoPorBoutique, setModalServiciosExtrasPeriodoPorBoutique ] = useState<boolean>(false)
  const handleShow_ModalServiciosExtrasPeriodoPorBoutique = (boutique:any) => {
    // cargarCobros_del_periodo_seleccionado(periodo_seleccionado)
    setBoutiqueSeleccionada(boutique)
    setModalServiciosExtrasPeriodoPorBoutique(true)
  }
  const handleClose_ModalServiciosExtrasPeriodoPorBoutique = () => {
    setModalServiciosExtrasPeriodoPorBoutique(false)
  }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Cobranza de Igualas de Servicios</h4>
                    <hr/>

                    <div className="row">


                      <div className="col-2">


                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Periodos de Servicio</h5>


                                        <Table responsive="lg">
                                          <thead style={{fontSize:9}}>
                                            <tr>
                                              <th scope="col">Inicio</th>
                                              <th scope="col">Fin</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              resultados.map( (cual:any,indice_periodo:number) =>
                                                  <tr key={indice_periodo} style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{cual.fecha_inicio}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{cual.fecha_fin}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" >
                                                    {
                                                      // JSON.stringify(cual.activa)
                                                    }
                                                        { cual.activa === 2 && (<i className="fa fa-clock-o" />)}
                                                        { cual.activa !== 2 && (<button onClick={(e:any)=>{cargarCobros_del_periodo_seleccionado(cual)}} className="btn btn-sm btn-link"><i className="fa fa-credit-card"/></button>)}
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                      </div>
                      <div className="col-10">
                        
                        {
                          !periodo_seleccionado && (

                              <React.Fragment>

                                  <div className="alert alert-danger" role="alert">
                                    Debes seleccionar un periodo para poder configurar los cobros
                                  </div>

                              </React.Fragment>

                            )
                        }


                        {
                          periodo_seleccionado && (

                              <React.Fragment>

                                <small>Periodo</small>
                                <h3  >DEL {periodo_seleccionado.fecha_inicio} AL {periodo_seleccionado.fecha_fin}</h3>

                             {
                               // <ul className="list-inline">
                               //    <li className="list-inline-item"><button onClick={handleShow_ModalServiciosExtras} type="button" className="btn btn-sm btn-link">Listado de Servicios que no entran en iguala</button></li>
                               //  </ul>
                             }
                                {
                                  // JSON.stringify(periodo_seleccionado)
                                }
                                        {
                                          // JSON.stringify(periodo_seleccionado.activa)
                                        }
                                        {
                                          // JSON.stringify(periodo_seleccionado.fecha_fin)
                                        }
                                        {
                                          // JSON.stringify(periodo_seleccionado.fecha_inicio)
                                        }


                                  {  envio_server && (

                                      <>
                                        <div className="loader">
                                          <div className="loaderBar"></div>
                                        </div>
                                      </>


                                   )}


                                  {  (!envio_server && periodo_seleccionado) && (

                                      <React.Fragment>


                                          {
                                            // JSON.stringify(cobros_del_periodo_seleccionado)
                                          }


                                          {
                                            cobros_del_periodo_seleccionado.map( (cliente:any,indice_cliente:number)=>
                                                <React.Fragment key={indice_cliente}>
                                                  <div className="shadow p-3 mb-2 bg-body-tertiary rounded">
                                                    <div className="row">
                                                      <div className="col-sm-3">
                                                        <small>Cliente</small>
                                                        <h6>{cliente.cliente}</h6>
                                                      </div>
                                                      <div className="col-sm-5">
                                                        <small>Razón Social</small>
                                                        <h6>{cliente.razon_social}</h6>
                                                      </div>
                                                      <div className="col-sm-4">
                                                        <small>Contacto</small>
                                                        <h6>{cliente.nombre_contacto}</h6>
                                                      </div>
                                                    </div>


                                                    <small>Boutiques</small>

                                                    <table className="table table-sm">
                                                      <thead style={{fontSize:12}}>
                                                        <tr>
                                                          <th scope="col">Boutique</th>
                                                          <th scope="col"> </th>
                                                          <th scope="col">TOTAL </th>
                                                          <th scope="col">TOTAL EXTRA </th>
                                                          <th scope="col">TOTAL REFACCIONAMIENTO</th>
                                                          <th scope="col">TOTAL IGUALA  </th>
                                                          <th scope="col">IGUALA MANTENIMIENTO  </th>
                                                          <th scope="col">IGUALA CONSERVACIÓN </th>
                                                          <th scope="col">IGUALA LIMPIEZA</th>

                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          cliente.boutiques.map((boutique:any,indice_boutique:number)=>
                                                              <tr key={indice_boutique}>
                                                                <td>
                                                                  <p className="nm"><b>{boutique.nombre_boutique}</b></p>
                                                                </td>

                                                                <td className="v text-left" style={{"width":"150px"}}>
                                                                  <p className="nm"><small>{boutique.acronimo_folio}</small></p>
                                                                </td>
                                                                <td className="v text-right  " style={{"width":"130px"}}>

                                                                  {
                                                                    // JSON.stringify(boutique)
                                                                  }


                                                                  { 
                                                                    // !boutique.tiene_servicios_mantenimiento && (<small className="nm" style={{fontSize:8}}>N/A</small>) 
                                                                  }
                                                                  {
                                                                    // boutique.tiene_servicios_mantenimiento && (
                                                                    //     <React.Fragment>
                                                                    //       {
                                                                    //         // JSON.stringify(boutique.pago_iguala_mantenimiento)
                                                                    //       }
                                                                    //       {
                                                                    //         // !boutique.pago_iguala_mantenimiento && (<button style={{fontSize:8}} onClick={(e:any)=>{ handleShow_ModalRegistroPagoIguala(boutique,"MANTENIMIENTO") }} className="btn btn-sm btn-link">Registrar Pago</button>)
                                                                    //       }
                                                                    //       {
                                                                    //         // boutique.pago_iguala_mantenimiento && (<button style={{fontSize:9}} onClick={(e:any)=>{ handleShow_ModalVisualizarRegistroPago(boutique,"MANTENIMIENTO") }} className="btn btn-sm btn-link">Pagado <i className="fa fa-check"/> </button>)
                                                                    //       }




                                                                    //     </React.Fragment>
                                                                    //   )
                                                                  }

                                                                  {/*<small><b>$ {
                                                                    formatToTwoDecimals((
                                                                         boutique.servicios_boutique_extras.reduce((total:any, servicio:any) => {
                                                                               if (servicio.presupuesto && servicio.presupuesto.material_actividades) {
                                                                                 return total + (servicio.presupuesto.costo_servicio||0) + servicio.presupuesto.material_actividades.reduce((subtotal:any, material:any) => {
                                                                                   return subtotal + material.costo * material.cantidad;
                                                                                 }, 0);
                                                                               }
                                                                               return total;
                                                                             }, 0)) +
                                                                     (
                                                                         boutique.servicios_boutique.reduce((total:any, servicio:any) => {
                                                                           if (servicio.presupuesto && servicio.presupuesto.material_actividades) {
                                                                             return total + (servicio.presupuesto.costo_servicio||0) + servicio.presupuesto.material_actividades.reduce((subtotal:any, material:any) => {
                                                                               return subtotal + material.costo * material.cantidad;
                                                                             }, 0);
                                                                           }
                                                                           return total;
                                                                         }, 0)
                                                                       ) +
                                                                     (boutique.servicio_mantenimiento ? boutique.monto_iguala_mantenimiento : 0 ) + (boutique.servicio_conservacion ? boutique.monto_iguala_conservacion : 0 ) + (boutique.servicio_limpieza ? boutique.monto_iguala_limpieza : 0 ) 
                                                                     // (boutique.servicio_mantenimiento ? (boutique.monto_iguala_mantenimiento) : 0 ) +
                                                                     // (boutique.servicio_conservacion ? (boutique.monto_iguala_conservacion) : 0 ) +
                                                                     // (boutique.servicio_limpieza ? (boutique.monto_iguala_limpieza) : 0 )
                                                                     )



                                                                  }</b></small>*/}

                                                                    <small>$ {' '}
                                                                    {
                                                                        formatToTwoDecimals(boutique.monto_servicios_extras + boutique.monto_refaccionamiento + ( boutique.monto_iguala_mantenimiento || 0 ) + (boutique.monto_iguala_conservacion || 0 ) + ( boutique.monto_iguala_limpieza || 0 ))
                                                                    }
                                                                    </small>


                                                                </td>
                                                                <td className="v text-right  " style={{"width":"130px"}}>
                                                                    <small>$ {' '}
                                                                    {
                                                                        formatToTwoDecimals(boutique.monto_servicios_extras)
                                                                    }
                                                                    </small>
                                                                </td>
                                                                <td className="v text-right  " style={{"width":"130px"}}>


                                                                {
                                                                  boutique.ya_se_pago_refaccionamiento_este_peridono && (<>✅{' '}</>)
                                                                }
                                                                <small>$ {' '}
                                                                {
                                                                //   !boutique.pago_refaccionamiento && (<small>$ {
                                                                //   formatToTwoDecimals(boutique.servicios_boutique.reduce((total:any, servicio:any) => {
                                                                //       if (servicio.presupuesto && servicio.presupuesto.material_actividades) {
                                                                //         return total + (servicio.presupuesto.costo_servicio||0) + servicio.presupuesto.material_actividades.reduce((subtotal:any, material:any) => {
                                                                //           return subtotal + material.costo * material.cantidad;
                                                                //         }, 0);
                                                                //       }
                                                                //       return total;
                                                                //     }, 0))


                                                                // }</small>)
                                                                    formatToTwoDecimals(boutique.monto_refaccionamiento)
                                                                }
                                                                </small>
                                                                {
                                                                  // boutique.pago_refaccionamiento && (<small> $
                                                                  // {
                                                                  // }

                                                                  //   {' '} <i  onClick={(e:any)=>{ handleShow_ModalVisualizarRegistroPago(boutique,"REFACCIONAMIENTO") }} className="fa fa-check"/>
                                                                  // </small>)
                                                                }


                                                                </td>
                                                                <td className="v text-right  " style={{"width":"130px"}}>
                                                                <small>
                                                                $ {
                                                                    formatToTwoDecimals( ( boutique.monto_iguala_mantenimiento || 0 ) + (boutique.monto_iguala_conservacion || 0 ) + ( boutique.monto_iguala_limpieza || 0 )   )
                                                                } 
                                                                </small>
                                                                </td>
                                                                <td className="v text-right tipo_orden_3" style={{"width":"130px"}}>
                                                                <small>
                                                                {
                                                                  boutique.ya_se_pago_iguala_mantenimiento_este_peridono && (<>✅{' '}</>)
                                                                }
                                                                  {
                                                                    boutique.tiene_servicios_mantenimiento && ( <>$ {formatToTwoDecimals(boutique.monto_iguala_mantenimiento)}</> )
                                                                  }
                                                                  {
                                                                    !boutique.tiene_servicios_mantenimiento && ( <>NO APLICA</> )
                                                                  }
                                                                </small>
                                                                </td>
                                                                <td className="v text-right tipo_orden_5" style={{"width":"130px"}}>
                                                                <small>
                                                                {
                                                                  boutique.ya_se_pago_iguala_conservacion_este_peridono && (<>✅{' '}</>)
                                                                }
                                                                  {
                                                                    boutique.tiene_servicios_conservacion && ( <>$ {formatToTwoDecimals(boutique.monto_iguala_conservacion)}</> )
                                                                  }
                                                                  {
                                                                    !boutique.tiene_servicios_conservacion && ( <>NO APLICA</> )
                                                                  }
                                                                </small>
                                                                </td>
                                                                <td className="v text-right tipo_orden_7" style={{"width":"130px"}}>
                                                                <small>
                                                                  {
                                                                    boutique.tiene_servicios_limpieza && ( <>$ {formatToTwoDecimals(boutique.monto_iguala_limpieza)}</> )
                                                                  }
                                                                  {
                                                                    !boutique.tiene_servicios_limpieza && ( <>NO APLICA</> )
                                                                  }
                                                                </small>
                                                                </td> 
                                                                <td className="v text-center tipo_orden_" style={{"width":"40px"}}>

                                                                {
                                                                  // JSON.stringify(boutique)
                                                                }

                                                                {
                                                                  // boutique.monto_servicios_extras
                                                                }

                                                                <Dropdown>
                                                                  <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                                                                    <i className="fa fa-money"/>
                                                                  </Dropdown.Toggle>
                                                                  <Dropdown.Menu>
                                                                    { (true) && (<Dropdown.Item onClick={(e:any)=>{handleShow_ModalServiciosExtrasPeriodoPorBoutique(boutique)}}>SERVICIOS EXTRAS</Dropdown.Item>)}
                                                                    { !boutique.pago_refaccionamiento && (<Dropdown.Item disabled={boutique.ya_se_pago_refaccionamiento_este_peridono} onClick={(e:any)=>{handleShow_ModalRegistroPagoServiciosDelPeriodo(boutique,"REFACCIONAMIENTO")}}>REFACCIONAMIENTO</Dropdown.Item>)}
                                                                    { (boutique.tiene_servicios_mantenimiento) && (<Dropdown.Item onClick={(e:any)=>{handleShow_ModalRegistroPagoServiciosDelPeriodo(boutique,"MANTENIMIENTO")}}>IGUALA MANTENIMIENTO</Dropdown.Item>)}
                                                                    { (boutique.tiene_servicios_conservacion) && (<Dropdown.Item onClick={(e:any)=>{handleShow_ModalRegistroPagoServiciosDelPeriodo(boutique,"CONSERVACION")}}>IGUALA CONSERVACIÓN</Dropdown.Item>)}
                                                                    { (boutique.tiene_servicios_limpieza) && (<Dropdown.Item onClick={(e:any)=>{handleShow_ModalRegistroPagoServiciosDelPeriodo(boutique,"LIMPIEZA")}}>IGUALA LIMPIEZA</Dropdown.Item>)}
                                                                  </Dropdown.Menu>
                                                                </Dropdown>


                                                                </td> 
                                                              </tr>
                                                            )
                                                        }
{

                                                              // <tr>
                                                              //   <td>

                                                              //   </td>
                                                              //   <td className="v text-left" style={{"width":"150px"}}>

                                                              //   </td>
                                                              //   <td className="v text-center tipo_orden_3" style={{"width":"100px"}}>
                                                              //     {
                                                              //       tieneServicios(cliente.boutiques_con_servicio,"MANTENIMIENTO") && (
                                                              //         <button style={{fontSize:8}} onClick={(e:any)=>{ handleShow_ModalRegistroPagoIgualaCliente(cliente,"MANTENIMIENTO") }} className="btn btn-sm btn-link">Registrar Pago</button>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-center tipo_orden_3" style={{"width":"100px"}}>
                                                              //     {
                                                              //       tieneServicios(cliente.boutiques_con_servicio,"MANTENIMIENTO") && (
                                                              //         <p className="nm"><b>$ { formatToTwoDecimals(sumarMontos(cliente.boutiques_con_servicio,"MANTENIMIENTO")) }</b></p>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-left tipo_orden_3" style={{"width":"40px"}}>
                                                              //     {
                                                              //       tieneServiciosExtras(cliente.boutiques_con_servicio,"MANTENIMIENTO") && (
                                                              //         <button style={{fontSize:8}} onClick={(e:any)=>{ handleShow_ModalRegistroPagoExtrasCliente(cliente,"MANTENIMIENTO") }} className="btn btn-sm btn-link">Registrar Pago</button>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-center tipo_orden_5" style={{"width":"100px"}}>
                                                              //     {
                                                              //       tieneServicios(cliente.boutiques_con_servicio,"CONSERVACION") && (
                                                              //         <button style={{fontSize:8}} onClick={(e:any)=>{ handleShow_ModalRegistroPagoIgualaCliente(cliente,"CONSERVACION") }} className="btn btn-sm btn-link">Registrar Pago</button>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-center tipo_orden_5" style={{"width":"100px"}}>
                                                              //     {
                                                              //       tieneServicios(cliente.boutiques_con_servicio,"CONSERVACION") && (
                                                              //         <p className="nm"><b>$ { formatToTwoDecimals(sumarMontos(cliente.boutiques_con_servicio,"CONSERVACION")) }</b></p>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-left tipo_orden_5" style={{"width":"40px"}}>
                                                              //     {
                                                              //       tieneServiciosExtras(cliente.boutiques_con_servicio,"CONSERVACION") && (
                                                              //         <button style={{fontSize:8}} onClick={(e:any)=>{ handleShow_ModalRegistroPagoExtrasCliente(cliente,"CONSERVACION") }} className="btn btn-sm btn-link">Registrar Pago</button>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-center tipo_orden_7" style={{"width":"100px"}}>
                                                              //     {
                                                              //       tieneServicios(cliente.boutiques_con_servicio,"LIMPIEZA") && (
                                                              //         <button style={{fontSize:8}} onClick={(e:any)=>{ handleShow_ModalRegistroPagoIgualaCliente(cliente,"LIMPIEZA") }} className="btn btn-sm btn-link">Registrar Pago</button>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-center tipo_orden_7" style={{"width":"100px"}}>
                                                              //     {
                                                              //       tieneServicios(cliente.boutiques_con_servicio,"LIMPIEZA") && (
                                                              //         <p className="nm"><b>$ { formatToTwoDecimals(sumarMontos(cliente.boutiques_con_servicio,"LIMPIEZA")) }</b></p>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-left tipo_orden_7" style={{"width":"40px"}}>
                                                              //     {
                                                              //       tieneServiciosExtras(cliente.boutiques_con_servicio,"LIMPIEZA") && (
                                                              //         <button style={{fontSize:8}} onClick={(e:any)=>{ handleShow_ModalRegistroPagoExtrasCliente(cliente,"LIMPIEZA") }} className="btn btn-sm btn-link">Registrar Pago</button>
                                                              //       )
                                                              //     }
                                                              //   </td>
                                                              //   <td className="v text-center" style={{"width":"50px"}}>

                                                              //   </td>
                                                              // </tr>
}

                                                      </tbody>
                                                    </table>

                                                    <small>* No se realizó ningún servicio</small>




                                                      {
                                                        // JSON.stringify(cliente.boutiques_con_servicio)
                                                      }

                                                  </div>
                                                </React.Fragment>
                                              )
                                          }




                                      </React.Fragment>


                                   )}



                              </React.Fragment>

                            )
                        }




                      </div>


                    </div>
                    
                </div>
              </div>

            </div>





  {
    show_ModalRegistroPagoIguala && (
      <ModalRegistroPagoIguala show={show_ModalRegistroPagoIguala} handleClose={handleClose_ModalRegistroPagoIguala} boutique={boutique_seleccionada} periodo={periodo_seleccionado} tipo_ingreso={tipo_ingreso_seleccionado} />
      )

  }





  {
    show_ModalVisualizarRegistroPago && (
      <ModalVisualizarRegistroPago show={show_ModalVisualizarRegistroPago} handleClose={handleClose_ModalVisualizarRegistroPago} boutique={boutique_seleccionada} periodo={periodo_seleccionado} tipo_servicio={tipo_ingreso_seleccionado} />
      )

  }





  {
    show_ModalRegistroPagoIgualaCliente && (
      <ModalRegistroPagoIgualaCliente show={show_ModalRegistroPagoIgualaCliente} handleClose={handleClose_ModalRegistroPagoIgualaCliente}  cliente={cliente_seleccionado} periodo={periodo_seleccionado} tipo_servicio={tipo_ingreso_seleccionado} sumarMontos={sumarMontos} />
      )

  }


  {
    show_ModalRegistroPagoExtrasCliente && (
      <ModalRegistroPagoExtrasCliente show={show_ModalRegistroPagoExtrasCliente} handleClose={handleClose_ModalRegistroPagoExtrasCliente}  cliente={cliente_seleccionado} periodo={periodo_seleccionado} tipo_servicio={tipo_ingreso_seleccionado} sumarMontosExtras={sumarMontosExtras} />
      )

  }



  {
    show_ModalServiciosExtras && (
      <ModalServiciosExtras show={show_ModalServiciosExtras} handleClose={handleClose_ModalServiciosExtras}  periodo={periodo_seleccionado} />
      )

  }






  {
    show_ModalRegistroPagoServiciosDelPeriodo && (
      <ModalRegistroPagoServiciosDelPeriodo show={show_ModalRegistroPagoServiciosDelPeriodo} handleClose={handleClose_ModalRegistroPagoServiciosDelPeriodo} handleCloseSuccess={handleClose_ModalRegistroPagoServiciosDelPeriodoLoagind} periodo={periodo_seleccionado} boutique={boutique_seleccionada} tipo_servicio={tipo_ingreso_seleccionado} />
      )

  }



  {
    show_ModalServiciosExtrasPeriodoPorBoutique && (
      <ModalServiciosExtrasPeriodoPorBoutique show={show_ModalServiciosExtrasPeriodoPorBoutique} handleClose={handleClose_ModalServiciosExtrasPeriodoPorBoutique} periodo={periodo_seleccionado} boutique={boutique_seleccionada} />
      )

  }


          </>

      );
  

}

export default CobranzaServicios
