import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'


import Select from 'react-select'


import AvatarEditor from 'react-avatar-editor'

import { rol } from '../../services/rol'


import Dialog from '../../services/Dialogs'

import municipiosListType from '../../interfaces/municipiosListType'


const REG_CURP = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
const REG_NOMBRES = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
const curp2date = (curp:any) => {
  var m = curp.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
  //miFecha = new Date(año,mes,dia) 
  var anyo = parseInt(m[1],10)+1900;
  if( anyo < 1950 ) anyo += 100;
  var mes = parseInt(m[2], 10)-1;
  var dia = parseInt(m[3], 10);
  return moment(new Date( anyo, mes, dia )).format('YYYY-MM-DD');
}





const ModalNuevoUsuario = ( params : any ) => {

  const { show, handleClose, tipo_alta_usuario } = params



  const dialog = new Dialog();


  // const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>({"value":2325,"label":"CAMPECHE - CAMPECHE","color":"#FFFFFF"})
  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>({})
  const [ etiquetasOptions, setEtiquetas ] = useState<municipiosListType[]>([]);

  const cargarMunicipios = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todos_los_municipios')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar todos_los_municipios')
    }
  }


  const [ subareas, setSubAreas ] = useState<any>([]);
  const cargarAreas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_subareas')
      setSubAreas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar todos_los_municipios')
    }
  }



  React.useEffect(() => {
    cargarMunicipios()
    cargarAreas()
  }, []);



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

  username: yup.string().required(mensajes_validacion_generico).matches(/^[a-z0-9_.]+$/,'La contraseña solo puede tener letras y números. No se aceptan espacios ni caracteres especiales'),
          foto : yup.string().required(mensajes_validacion_generico),
          nombres : yup.string().required(mensajes_validacion_generico),
          paterno : yup.string().required(mensajes_validacion_generico),
          materno : yup.string(),

        direccion : yup.string().required(mensajes_validacion_generico),
        cp : yup.string().required(mensajes_validacion_generico),
        idmunicipio : yup.number().required(mensajes_validacion_generico),
        idsubarea : yup.string().required(mensajes_validacion_generico),


  curp: yup.string().required(mensajes_validacion_generico).matches( REG_CURP , "Formato de CURP invalido").required('El CURP es un campo obligatorio'),
  seguro_social: yup.string().required(mensajes_validacion_generico),
  rfc: yup.string().required(mensajes_validacion_generico),
  email: yup.string().required(mensajes_validacion_generico).email('Formato de Email no valido').required('Este campo es obligatorio'),
  celular: yup.string().required(mensajes_validacion_generico).matches(/^\d{10}$/,"Formato no valido"),


  fecha_ingreso : yup.string().required(mensajes_validacion_generico),

  rol : yup.string().required(mensajes_validacion_generico),

          dia_descanso : yup.number().nullable().required(mensajes_validacion_generico),

          foraneo : yup.boolean()

  });





const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: `
          <p class="text-center">
            <img className="img mt-4" src="${registro.foto}" width="120px" />
          </p>
          <small>Nombre(s)</small>
          <p class="nm">${registro.nombres} ${registro.paterno} ${registro.materno} </p>
          <small>CURP</small>
          <p class="nm">${registro.curp} </p>
          <small>RFC</small>
          <p class="nm">${registro.rfc} </p>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)

        let URL_CREATE = `usuarios/crear_usuario`

        let res_ = await RequestService.create( URL_CREATE , { ...registro, fecha_nacimiento : curp2date(registro.curp), foto : registro.foto.split(',')[1], fecha_ingreso : moment(registro.fecha_ingreso).format('YYYY-MM-DD') })

        if(res_){
          toast.success('El Usuario fue creado correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  const [ editor, setEditorRef ] = useState<any>(null)

  const [imgSrc, setImgSrc] = useState('')
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // setCrop({undefined}) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        let imagen = reader.result?.toString() || ''
        if(imagen!==''){
          var imag_ = new Image();
          imag_.src = imagen;
          // console.log(imag_)
          // var newDataUri = imageToDataUri(imag_, targetWidth, targetHeight)
        }
        setImgSrc(imagen)

      })
      reader.readAsDataURL(e.target.files[0])
    }
  }


  const [ skale, setSkale ] = useState<any>(1)


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Alta de Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }






    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={
        {

            username : "", 
            foto : "", 
            foto_ : "", 
            nombres : "", 
            paterno : "", 
            materno : "", 
            rol : "", 
            celular : "", 
            email : "", 
            curp : "", 
            rfc : "", 
            seguro_social : "", 

            idsubarea : "", 
            idmunicipio : "", 
            direccion : "", 
            cp : "", 

            dia_descanso : "", 

            fecha_ingreso : new Date(),

            foraneo : true


      }

        // {"username":"johnsmith","foto":"","foto_":"","nombres":"RICARDO","paterno":"PÉREZ","materno":"","rol":"","celular":"5551111111","email":"johndoe@example.com","curp":"GOMA010101HDFXXX01","rfc":"ABCD920101123","seguro_social":"ABCD920101123","idmunicipio":"100","direccion":"domicilio cono","cp":"20200","idarea":""}



    }
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


        {
          // JSON.stringify(values)
        }

              <div className="alert alert-primary" role="alert">
                


                        <Row>
                          <Form.Group as={Col} md="6" className="mb-3" controlId="username">
                            <Form.Label>Nombre de Usuario</Form.Label>
                            <Form.Control
                              required
                              name="username"
                              value={values.username}
                              onChange={(e)=>{ setFieldValue('username', (e.target.value) ) }}
                              isInvalid={!!errors.username}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          </Form.Group>
                          {

                            tipo_alta_usuario === 'CLIENTE' && (

                              <>

                                  DAR DE ALTA UN USUARIO DEL CLIENTE PARA VER SUS COSITAS HIHIHI

                              </>

                            )
                          }

                          {

                            tipo_alta_usuario === 'RECURSOS_HUMANOS' && (

                              <>

                          <Form.Group as={Col} md="6" className="mb-3" controlId="rol">
                            <Form.Label>Tipo de Acceso</Form.Label>
                            <Form.Select required value={values.rol} onChange={(e)=>{ setFieldValue('rol',e.target.value) }} isInvalid={!!errors.rol} >
                              <option>Selecciona un tipo de Usuario</option>
                                    <optgroup label="Operación">
                                        <option value="ROLE_LIMPIEZA">{rol('ROLE_LIMPIEZA')}</option>
                                        <option value="ROLE_TECNICO">{rol('ROLE_TECNICO')}</option>
                                        <option value="ROLE_RESPONSABLE_TECNICOS">{rol('ROLE_RESPONSABLE_TECNICOS')}</option>
                                        <option value="ROLE_RESPONSABLE_LIMPIEZA">{rol('ROLE_RESPONSABLE_LIMPIEZA')}</option>
                                        <option value="ROLE_RESPONSABLE_PRESUPUESTO">{rol('ROLE_RESPONSABLE_PRESUPUESTO')}</option>
                                        <option value="ROLE_RESPONSABLE_OPERACION">{rol('ROLE_RESPONSABLE_OPERACION')}</option>
                                    </optgroup>

                                    <optgroup label="Oficina">
                                    
                                        <option value="ROLE_JEFE_ADMIN">{rol('ROLE_JEFE_ADMIN')}</option>
                                        <option value="ROLE_CEO">{rol('ROLE_CEO')}</option>

                                        <option value="ROLE_CONTABILIDAD">{rol('ROLE_CONTABILIDAD')}</option>
                                        <option value="ROLE_RECURSOS_HUMANOS">{rol('ROLE_RECURSOS_HUMANOS')}</option>
                                        <option value="ROLE_SISTEMAS">{rol('ROLE_SISTEMAS')}</option>
                                        <option value="ROLE_RECURSOS_MATERIALES">{rol('ROLE_RECURSOS_MATERIALES')}</option>
                                        <option value="ROLE_SHEI">{rol('ROLE_SHEI')}</option>
                                        <option value="ROLE_ATENCION_CLIENTES">{rol('ROLE_ATENCION_CLIENTES')}</option>
                                        <option value="ROLE_ALMACEN">{rol('ROLE_ALMACEN')}</option>
                                        <option value="ROLE_MARKETING">{rol('ROLE_MARKETING')}</option>
                                        <option value="ROLE_RECLUTAMIENTO">{rol('ROLE_RECLUTAMIENTO')}</option>

                                        <option value="RESIDENTE_OBRA">{rol('RESIDENTE_OBRA')}</option>
                                        <option value="AUXILIAR_OBRA">{rol('AUXILIAR_OBRA')}</option>
                                        <option value="ELECTRICO">{rol('ELECTRICO')}</option>
                                        <option value="AYUDANTE_ELECTRICO">{rol('AYUDANTE_ELECTRICO')}</option>
                                        <option value="TABLAROQUERO">{rol('TABLAROQUERO')}</option>
                                        
                                        <option value="ROLE_COMPRAS">{rol('ROLE_COMPRAS')}</option>
                                        <option value="ROLE_COMPROBACIONES">{rol('ROLE_COMPROBACIONES')}</option>

                                    </optgroup>



                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.rol} </Form.Control.Feedback>
                          </Form.Group>

                              </>

                            )
                          }


                          <div className="row">

                              <Form.Group as={Col} md="6" className="mb-3" controlId="idsubarea">
                                <Form.Label>Subarea</Form.Label>
                                <Form.Select required value={values.idsubarea} onChange={(e)=>{ setFieldValue('idsubarea',e.target.value) }} isInvalid={!!errors.idsubarea} >
                                  <option>Selecciona una subarea</option>
                                    {
                                      subareas.map((subarea:any,index_subareas:number)=>
                                        <option key={index_subareas} value={subarea.uuid}>{subarea.subarea}</option>
                                      )
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.idsubarea} </Form.Control.Feedback>
                              </Form.Group>


                              <Form.Group as={Col} md="6" className="mb-3" controlId="dia_descanso">
                                <Form.Label>Día de Descanso</Form.Label>
                                <Form.Select required value={values.dia_descanso} onChange={(e)=>{ setFieldValue('dia_descanso',e.target.value) }} isInvalid={!!errors.dia_descanso} >
                                  <option>Selecciona un día</option>
                                    {
                                      ["DOMINGO","LUNES","MARTES","MIÉRCOLES","JUEVES","VIERNES","SÁBADO"].map((dia:any,i:number)=>
                                        <option key={i} value={i}>{dia}</option>
                                      )
                                    }
                                </Form.Select>
                                  <Form.Control.Feedback type="invalid">{errors.dia_descanso}</Form.Control.Feedback>
                              </Form.Group>

                          </div>





                          <Form.Group as={Col}   className="mb-3" controlId="xx">
                              <Form.Check 
                                  onChange={e => setFieldValue('foraneo', e.target.checked)}
                                  checked={values.foraneo}
                                  type={'checkbox'}
                                  id={`default-`}
                                  label={`Marcar este usuario como FORANEO`}
                                />
                            </Form.Group>



                        </Row>

                        <div className="row">
                          <div className="col-sm-6">
                              <div className="mb-3">
                                <Form.Label>Fecha de Ingreso</Form.Label>
                                <DatePicker selected={values.fecha_ingreso} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_ingreso', date ) } />
                              </div>
                          </div>
                        </div>



                    <hr/>
                    <p className="mb-0">Considera que por default la contraseña del nuevo usuario será su número de Celular. Recuerda que el usuario será responsable de cambiar su contraseña al acceder a la plataforma.</p>


              </div>





                  <h6>Información Personal</h6>


                  <Row>
                    <Col md="3">

                    {
                      values.foto === "" && (

                          <>

                            <Form.Group className="mb-3" controlId="foto">
                              <Form.Label>Selecciona una foto</Form.Label>
                              <Form.Control
                                required
                                type="file"
                                name="foto"
                                value={undefined}
                                accept="image/*" onChange={onSelectFile}
                                isInvalid={!!errors.foto}
                              />
                              <Form.Control.Feedback type="invalid">{errors.foto}</Form.Control.Feedback>
                            </Form.Group>

                                {
                                  imgSrc && (
                                      <>

                                        <AvatarEditor ref={setEditorRef} image={imgSrc} width={150} height={150} border={2} scale={skale} />

                                        <p>
                                          <input type='range' onChange={(e:any)=>{setSkale(e.target.value)}} min={1} max={10} step={.1} value={skale} className='custom-slider' />
                                        </p>

                                        <button type="button" onClick={(e)=>{ setFieldValue('foto',editor.getImage().toDataURL() ) }} className="btn btn-link"><small>Usar Foto <i className="fa fa-check-circle"/></small></button>

                                      </>
                                    )
                                }


                          </>

                        )
                    }

                    {
                      values.foto !== "" && (

                          <>
                              {
                                // JSON.stringify(values.foto)
                              }
                              <img className="img mt-4" src={values.foto} width="100%" />
                          </>

                        )
                    }

                      
                    </Col>
                    <Col md="9">
                      


                    <Form.Group   className="mb-3" controlId="nombres">
                      <Form.Label>Nombre(s)</Form.Label>
                      <Form.Control
                        required
                        name="nombres"
                        value={values.nombres}
                        onChange={(e)=>{ setFieldValue('nombres', (e.target.value.toUpperCase()) ) }}
                        isInvalid={!!errors.nombres}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.nombres}</Form.Control.Feedback>
                    </Form.Group>




                    <div className="row">
                      <div className="col-md-6">

                          <Form.Group   className="mb-3" controlId="paterno">
                            <Form.Label>Apellido Paterno</Form.Label>
                            <Form.Control
                              required
                              name="paterno"
                              value={values.paterno}
                              onChange={(e)=>{ setFieldValue('paterno', (e.target.value.toUpperCase()) ) }}
                              isInvalid={!!errors.paterno}
                            />
                            <Form.Control.Feedback type="invalid">{errors.paterno}</Form.Control.Feedback>
                          </Form.Group>

                      </div>
                      <div className="col-md-6">
                          <Form.Group   className="mb-3" controlId="materno">
                            <Form.Label>Apellido Materno</Form.Label>
                            <Form.Control
                              name="materno"
                              value={values.materno}
                              onChange={(e)=>{ setFieldValue('materno', (e.target.value.toUpperCase()) ) }}
                              isInvalid={!!errors.materno}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                    </div>



          <Row >
            <Form.Group as={Col} md="6" className="mb-3" controlId="curp">
              <Form.Label>CURP </Form.Label>
              <Form.Control
                type="text"
                required
                name="curp"
                value={values.curp}
                onChange={(e)=>{ setFieldValue('curp', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.curp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.curp} </Form.Control.Feedback>
            </Form.Group>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Sexo</label>
                <input type="text" readOnly value={values.curp.substr(10,1)==='H'?'HOMBRE':'MUJER'} className="form-control" />
              </div>
                )
              }
            </Col>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Fecha de Nacimiento</label>
                <input type="text" readOnly value={curp2date(values.curp)} className="form-control" />
              </div>
                )
              }
            </Col>
          </Row>




          <Row >
            <Form.Group as={Col} md="5" className="mb-3" controlId="rfc">
              <Form.Label>RFC </Form.Label>
              <Form.Control
                type="text"
                required
                name="rfc"
                value={values.rfc}
                onChange={(e)=>{ setFieldValue('rfc', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.rfc}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.rfc} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3" controlId="seguro_social">
              <Form.Label>Seguro Social </Form.Label>
              <Form.Control
                type="text"
                required
                name="seguro_social"
                value={values.seguro_social}
                onChange={(e)=>{ setFieldValue('seguro_social', (e.target.value) ) }}
                isInvalid={!!errors.seguro_social}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.seguro_social} </Form.Control.Feedback>
            </Form.Group>
          </Row>




                    </Col>
                  </Row>






          <h5>Información de Contacto</h5>




            <Form.Group   className="mb-3" controlId="direccion">
              <Form.Label>Dirección de Contacto</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
            </Form.Group>



            <div className="row">
              <div className="col-sm-8">

{
  // JSON.stringify(etiquetas_seleccionadas)
}

                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Municipio</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="idmunicipio"
                        value={values.idmunicipio}
                        onChange={(e:any)=>{}}
                        isValid={touched.idmunicipio && !errors.idmunicipio}
                        isInvalid={!!errors.idmunicipio}
                      />
                            <Select
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt); setFieldValue('idmunicipio',opt.value) }}
                              isClearable={false}
                              name="colors"
                              defaultValue={etiquetas_seleccionadas||'Seleccionar'}
                              options={etiquetasOptions}
                              className={` ${ (errors.idmunicipio?'is-invalid':'') }`}
                              styles={{ control: styles => ({ ...styles, borderColor: errors.idmunicipio ? 'red' : '#ced4da' }) }}
                              classNamePrefix="select"
                            />
                      <Form.Control.Feedback type="invalid">{errors.idmunicipio}</Form.Control.Feedback>
                    </Form.Group>



              </div>
              <div className="col-sm-4">

                    <Form.Group className="mb-3" controlId="cp">
                      <Form.Label>Código Postal</Form.Label>
                      <Form.Control
                        required
                        name="cp"
                        value={values.cp}
                        onChange={(e)=>{ setFieldValue('cp', (e.target.value) ) }}
                        isInvalid={!!errors.cp}
                      />
                    </Form.Group>

              </div>
            </div>



          <Row>
            <Form.Group as={Col} md="7" className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                required
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email', e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.email} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                required
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular',e.target.value) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.celular} </Form.Control.Feedback>
            </Form.Group>
          </Row>





        </Form>
      )}
    </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoUsuario;