import React, { useState } from "react";
import RequestService from "../../services/request.service";


import AuthService from '../../features/auth/auth.service'


import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'

import Dialog from '../../services/Dialogs'


import ModalDetallePresupuesto from './ModalDetallePresupuesto'
import ModalTerminarPresupuesto from './ModalTerminarPresupuesto'



import ModalCobros from './ModalCobros'


import ModalReporteRefaccionamiento from './ModalReporteRefaccionamiento'


import { tipoPresupuesto, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'


import { rol } from '../../services/rol'


import './colores_presupuestos.scss'


import { SERVER_ } from  '../../config';


const Presupuestos = () =>{


  const user = AuthService.getCurrentUser()


  const dialog = new Dialog()


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`operacion/presupuestos/${arreglo_estatus}/${arreglo_tipo_presupuesto}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    setCurrentPage(1)
    cargarMiReporte(0)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true,true,true,true,true,true])
  const [ arreglo_tipo_presupuesto, setTipoPresupuesto ] = useState<any>([true,true,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true,true,true,true,true,true])
    setTipoPresupuesto([true,true,true,true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false,false,false])
    setTipoPresupuesto([false,false,false,false,false,false,false,false])
  }











  const [ presupuesto_seleccionado, setPresupuesto ] = useState<any>({})

  const [ show_ModalDetallePresupuesto, setModalDetallePresupuesto ] = useState<boolean>(false)
  const handleShow_ModalDetallePresupuesto = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalDetallePresupuesto(true)
  }
  const handleClose_ModalDetallePresupuesto = () => {
    // cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalDetallePresupuesto(false)
  }

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const [ show_ModalValidarPresupuesto, setModalValidarPresupuesto ] = useState<boolean>(false)
  const handleShow_ModalValidarPresupuesto = async(presupuesto:any) => {

    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`presupuesto/${presupuesto.uuid_presupuesto}`)
      // setDetalle_presupuesto(response.data)

    setPresupuesto(response.data)
    setModalValidarPresupuesto(true)


      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }




  }
  const handleClose_ModalValidarPresupuesto = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalValidarPresupuesto(false)
  }







const eliminarPresupuesto = async (event:any,uuid_presupuesto:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `presupuesto/${uuid_presupuesto}` )

        if(res_){
          toast.warning('El Presupuesto fue eliminado correctamente')
          setTimeout(()=>{
            // handleClose()
            cargarMiReporte(currentPage===0?0:currentPage-1)
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





  React.useEffect(() => {
    cargarMiReporte(0)
  }, []);




  const [ show_ModalCobros, setModalCobros ] = useState<boolean>(false)
  const handleShow_ModalCobros = () => {
    setModalCobros(true)
  }
  const handleClose_ModalCobros = () => {
    setModalCobros(false)
  }



 
const asignarVisitaAPresupuesto = async (event:any,uuid_presupuesto:string) =>{

      await dialog.open({
        accept: 'Crear',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<p>Se asignará una visita de entrega a este presupuesto</p>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `presupuesto/asignar_visita/${uuid_presupuesto}` , { })

        if(res_){
          toast.success('La visita de entrega fué registrada')
          setTimeout(()=>{
            busqueda()
          },0)
        }
        else{
          toast.error('Problemas al registrar la visita...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}




  const [ show_ModalReporteRefaccionamiento, setModalReporteRefaccionamiento ] = useState<boolean>(false)
  const handleShow_ModalReporteRefaccionamiento = () => {
    setModalReporteRefaccionamiento(true)
  }
  const handleClose_ModalReporteRefaccionamiento = () => {
    setModalReporteRefaccionamiento(false)
  }


 


      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Gestión de Presupuestos  </h4>
                    <hr/>



                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>

                        <div>
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="NUEVA SOLICITUD" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="CREANDO PRESUPUESTO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="APROBACIÓN GERENCIA" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="APROBACIÓN CLIENTE" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ACEPTADA POR CLIENTE" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                            {/*<Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="INCLUIDO EN IGUALA" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />*/}
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ACEPTADA" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="RECHAZADA" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="REVISIÓN FINAL" checked={arreglo_estatus[9]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[9] = e.target.checked;  setEstatus(ne);}} id={`opciones_10`} />
                        </div>



                        <div>
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('POR_DEFINIR')} checked={arreglo_tipo_presupuesto[0]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[0] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_0`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('PRESUPUESTO_SUMINISTRO_INSTALACION')} checked={arreglo_tipo_presupuesto[1]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[1] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_1`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('PRESUPUESTO_CONSERVACION')} checked={arreglo_tipo_presupuesto[2]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[2] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_2`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('PRESUPUESTO_INSTALACION')} checked={arreglo_tipo_presupuesto[3]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[3] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_3`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('PRESUPUESTO_REPARACION')} checked={arreglo_tipo_presupuesto[4]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[4] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_4`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('PRESUPUESTO_SUMINISTRO')} checked={arreglo_tipo_presupuesto[5]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[5] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_5`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('PRESUPUESTO_INSUMOS')} checked={arreglo_tipo_presupuesto[6]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[6] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_6`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label={tipoPresupuesto('PRESUPUESTO_LIMPIEZA')} checked={arreglo_tipo_presupuesto[7]} onChange={(e)=>{ var ne = [...arreglo_tipo_presupuesto]; ne[7] = e.target.checked;  setTipoPresupuesto(ne);}} id={`tipo_presupuesto_7`} />
                        </div>



                        <ul className="list-inline">
                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                          
                              {
                                ["ROLE_RESPONSABLE_PRESUPUESTO","ROLE_ADMIN"].indexOf(user.rol) > -1 && (<li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalCobros()}}>Refaccionamiento</small></li>)
                              }

                              {
                                ["ROLE_RESPONSABLE_PRESUPUESTO","ROLE_ADMIN"].indexOf(user.rol) > -1 && (<li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalReporteRefaccionamiento()}}>Reporte de Refaccionamiento</small></li>)
                              }
            
                        </ul>



          
                    </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio / Boutique</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col">Usuario</th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col">Fecha</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (presupuesto:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12, background : (["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1 ? '#e8e8e8' : '') }}>
                                                    <td onClick={(e:any)=>{handleShow_ModalDetallePresupuesto(presupuesto)}} className={`v pointer text-left -estatus_${presupuesto.idestatus_presupuesto.id}`} style={{"width":"200px"}}>
                                                        <p className="nm"><small><b>{presupuesto.folio} {presupuesto.visita&&(<i className="fa fa-asterisk"/>)} </b></small></p>                                                            
                                                        {presupuesto.idboutique&&(<small>{presupuesto.idboutique?.nombre_boutique}</small>)}
                                                    </td>
                                                    <td className={`v text-center estatus_presupuesto_${presupuesto.idestatus_presupuesto.id}`} style={{"width":"160px"}} >
                                                      <p className="nm">
                                                          {presupuesto.idestatus_presupuesto.estatus}
                                                      </p>
                                                      {
                                                        // JSON.stringify(presupuesto.visita.idestatus_atencion)
                                                      }
                                                    </td>

                                                        <td className="v text-center" style={{width:40}}>
                                                          {
                                                            presupuesto.entra_iguala === false && ( <small title="NO Entra en iguala">💰</small> )
                                                          }
                                                        </td>
                                                        <td className="v text-center" style={{width:40}}>
                                                          <a href={`${SERVER_}ficha_presupuesto/${presupuesto.uuid_presupuesto}`} target="_blank"><i className="fa fa-print"/></a>
                                                        </td>
                                                    {

                                                    // <td className="v text-left"  >
                                                    //   <p><small>{presupuesto.descripcion}</small></p>
                                                    // </td>
                                                    }
                                                    <td className="v text-left"  >
                                                      <p className="nm"><small>{presupuesto.descripcion}</small>   </p>
                                                      { presupuesto.folio_servicio && (<>
                                                        
                                                        <small>
                                                          ESTATUS DE LA VISITA {' '}
                                                          <span style={{padding:'2px 2px'}} className={`nm estatus_atencion_${presupuesto.visita?.idestatus_atencion?.id}`}>{presupuesto.visita.idestatus_atencion.estatus}</span>
                                                        </small>

                                                      </>) }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"300px"}} >
                                                      <p className="nm"><b>{presupuesto.idusuario.nombres}</b> <small>{presupuesto.idusuario.paterno} {presupuesto.idusuario.materno}</small></p>
                                                      {rol(presupuesto.idusuario.rol)}
                                                    </td>

                                                    <td className={`v text-left ${presupuesto.tipo_presupuesto}`} style={{"width":"130px"}} >
                                                      <p className="nm"><small><b>{tipoPresupuesto(presupuesto.tipo_presupuesto)}</b></small></p>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <p className="nm"><b>{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD')}</b></p>
                                                        <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        {
                                                          // JSON.stringify(cual)
                                                        }



                                                        {
                                                          [2].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (
                                                                <Button onClick={(e:any)=>{handleShow_ModalValidarPresupuesto(presupuesto)}} size="sm" variant="warning"><i className="fa fa-certificate"/></Button>
                                                            )
                                                        }
                                                        


                                                        {
                                                          ([6,7].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && presupuesto.tipo_presupuesto === "PRESUPUESTO_INSUMOS" && !presupuesto.visita ) && (
                                                                <Button onClick={(e:any)=>{asignarVisitaAPresupuesto(e,presupuesto.uuid_presupuesto)}} size="sm" variant="primary"><i className="fa fa-certificate"/></Button>
                                                            )
                                                        }
                                                        
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                          {
                                                            !presupuesto.folio_servicio && <Button onClick={(e:any)=>{eliminarPresupuesto(e,presupuesto.uuid_presupuesto)}} size="sm" variant="danger"><i className="fa fa-trash"/></Button>
                                                          }
                                                          
                                                      {
                                                        // JSON.stringify(presupuesto.folio_servicio)
                                                      }
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}
                    
                </div>
              </div>

            </div>





  {
    show_ModalDetallePresupuesto && (
      <ModalDetallePresupuesto show={show_ModalDetallePresupuesto} handleClose={handleClose_ModalDetallePresupuesto} presupuesto={presupuesto_seleccionado} />
      )

  }





  {
    show_ModalValidarPresupuesto && (
      <ModalTerminarPresupuesto show={show_ModalValidarPresupuesto} handleClose={handleClose_ModalValidarPresupuesto} presupuesto={presupuesto_seleccionado} />
      )

  }


  {
    show_ModalCobros && (
      <ModalCobros show={show_ModalCobros} handleClose={handleClose_ModalCobros}  />
      )

  }



  {
    show_ModalReporteRefaccionamiento && (
      <ModalReporteRefaccionamiento show={show_ModalReporteRefaccionamiento} handleClose={handleClose_ModalReporteRefaccionamiento}  />
      )

  }




          </>

      );
  

}

export default Presupuestos
