import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import { Form, Col, Row, Tabs, Tab } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

import { calcularTiempoTranscurrido, evaluarAtencion, evaluarAtencionGlobal } from '../../services/funcionesPublicas'


import { SERVER_ } from  '../../config';


const ModalReporteEvidenciasVisuales = ( params : any ) => {


  const user = AuthService.getCurrentUser()

  const { show, handleClose, registro, tipo } = params

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const [ idreporte_evidencia_visual, setReporteVisualHecho ] = useState<any>(registro.idreporte_evidencia_visual?registro.idreporte_evidencia_visual:null)


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          folio : yup.string().required(mensajes_validacion_generico),
          descripcion : yup.string().required(mensajes_validacion_generico),

  });




  const enviarFormulario = async (registros:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: '<small>Una vez creado el reporte podrás anexas las imágenes</small>'
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `visita/reporte_evidencias_visuales/crear_reporte_evidencias_visuales/${registro.uuid}` , { ...registros })

          if(res_){
            toast.success('El Reporte se creó correctamente. Ahora puedes subir imágenes de evidencia')
            // console.log(res_)
            setReporteVisualHecho(res_.data)
            // setTimeout(()=>{
              // params.handleClose()
            // },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }






  const [files, setFiles] = useState<any[]>([]);

  // Función para manejar el cambio de los archivos
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);

    // Filtrar archivos válidos (png o jpg)
    const validFiles = selectedFiles.filter((file) =>
      file.type === "image/png" || file.type === "image/jpeg"
    );

    // Procesar cada archivo seleccionado
    validFiles.forEach((file) => {
      // Si el archivo es mayor a 2MB, lo comprimimos
      if (file.size > 2 * 1024 * 1024) {
        compressImage(file).then((compressedBase64) => {
          setFiles((prevFiles) => [
            ...prevFiles,
            { base64: compressedBase64, name: file.name },
          ]);
        });
      } else {
        // Si no es necesario comprimir, solo convertir a Base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setFiles((prevFiles) => [
            ...prevFiles,
            { base64: reader.result, name: file.name },
          ]);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  // Función para comprimir la imagen usando canvas
  const compressImage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onloadend = () => {
        img.src = reader.result as string;
      };
      reader.readAsDataURL(file);

      img.onload = () => {
        const MAX_WIDTH = 1024; // Ancho máximo
        const MAX_HEIGHT = 1024; // Alto máximo

        // Determinamos el tamaño de la imagen redimensionada
        let width = img.width;
        let height = img.height;

        // Si la imagen es más grande que las dimensiones máximas, redimensionamos
        if (width > height) {
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }

        // Creamos un canvas para redimensionar la imagen
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (ctx) {
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Comprimimos la imagen y la convertimos a Base64
          const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7); // 0.7 es la calidad
          resolve(compressedBase64);
        } else {
          reject("Error al obtener contexto del canvas.");
        }
      };

      img.onerror = () => {
        reject("Error al cargar la imagen.");
      };
    });
  };

  // Función para eliminar una imagen del arreglo
  const removeImage = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };









const actualizarAnexo = async (event:any,uuid_anexo:string, descripcion:string) => {

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `reporte_evidencias_visuales/actualizar_anexo/${uuid_anexo}`, { descripcion : descripcion } )

        if(res_){
          toast.success('La descripción del anexo fué actualizado')
          cargarReporte_evidencia_visual()
          // setTimeout(()=>{
          //   cargar()
          // },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  const cargarReporte_evidencia_visual = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`reporte_evidencias_visuales/${idreporte_evidencia_visual.uuid}`)
      setReporteVisualHecho(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }




const eliminarAnexo = async (event:any,uuid_anexo:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `reporte_evidencias_visuales/eliminar_anexo/${uuid_anexo}` )

        if(res_){
          toast.warning('El anexo fue eliminado del reporte')
          cargarReporte_evidencia_visual()
          // setTimeout(()=>{
          //   cargar()
          // },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}






const girarFoto = async (event:any,uuid_anexo:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `reporte_evidencias_visuales/girar_anexo/${uuid_anexo}`, { } )

        if(res_){
          toast.success('La descripción del anexo fué actualizado')
          cargarReporte_evidencia_visual()
          // setTimeout(()=>{
          //   cargar()
          // },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





const agregarImagenesReporte = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<small>Las fotos de agregarán al Reporte de Evidencias Visuales</small>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.getEndPointPost( `reporte_evidencias_visuales/subir/${idreporte_evidencia_visual.uuid}` , { imagenes : files })

        if(res_){
          toast.success('El Reporte se actualizó')
          setTimeout(()=>{
            cargarReporte_evidencia_visual()
            setFiles([])
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}






  return (
    <>
      <Modal show={show} fullscreen={true} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Evidencias Visuales</Modal.Title>
        </Modal.Header>
        <Modal.Body>


            <div className="row">
              <div className="col-md-4">
                  <small>Boutique</small>
                  <h5>{registro.nombre_boutique? registro.nombre_boutique : registro.idboutique.nombre_boutique }</h5>                
              </div>
              <div className="col-md-4">
                  <small>Folio de Servicio</small>
                  <h5>{registro.folio }</h5>                
              </div>
              <div className="col-md-4">
                  <small>Fecha del Reporte</small>
                  <h5 className="nm">{moment(registro.fecha_creacion).format('YYYY-MM-DD')}</h5>
                  <p className="nm mb-4"><small>{calcularTiempoTranscurrido(registro.fecha_creacion)}</small></p>                
                  <br/>
              </div>
            </div>


            {
              idreporte_evidencia_visual && (
                    <React.Fragment>
                      {
                        // JSON.stringify(idreporte_evidencia_visual)
                      }
                      <Tabs defaultActiveKey="home" className="mb-3" >
                        <Tab eventKey="home" title={`💾 Cargar`}>
                          <p>Selecciona las imágenes que quieres agregar al Reporte:</p>
                          <p><small>🛟 Las fotos que selecciones no se agregarán al reporte hasta que de clic en el botón <b>CARGAR IMÁGENES</b></small></p>


                          <h4 className="mb-4">Sube tus imágenes</h4>

                          {/* Formulario de carga de archivos */}
                          <div className="mb-3">
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              multiple
                              onChange={handleFileChange}
                              className="form-control"
                            />
                          </div>

                          {/* Lista de imágenes cargadas */}
                          {files.length > 0 && (
                            <div>
                              <h3 className="mb-3">Imágenes para cargar:</h3>
                              <div className="row">
                                {files.map((file, index) => (
                                  <div className="col-4 mb-3" key={index}>
                                    <div className="position-relative">
                                      <img
                                        src={file.base64}
                                        alt={file.name}
                                        className="img-fluid rounded"
                                        style={{ width: "100%", objectFit: "cover" }}
                                      />
                                      <button
                                        onClick={() => removeImage(index)}
                                        className="btn btn-danger btn-sm position-absolute top-0 end-0 m-2"
                                        style={{ zIndex: 1 }}
                                      >
                                        ❌ {/* Emoji de eliminación */}
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}


                          {
                            files.length > 0 && (
                                <Button onClick={agregarImagenesReporte} type="button" size="lg" disabled={envio_server} variant="success" className="w-100"> Cargar Imágenes</Button>
                              )
                          }

                        </Tab>
                        <Tab eventKey="profile" title={`📷 Reporte`}>





                            {
                              idreporte_evidencia_visual.anexos.length === 0 && (
                                  <React.Fragment>
                                    <div className="alert alert-warning mt-3" role="alert">
                                      <p className="nm"><i className="fa fa-warning"/> Aún no cargas ninguna evidencia visual.</p>
                                    </div>
                                  </React.Fragment>
                                )
                            }

                            {
                              idreporte_evidencia_visual.anexos.length > 0 && (
                                  <React.Fragment>
                                    {
                                      // JSON.stringify(idreporte_evidencia_visual.anexos.length)
                                    }

                                    <div className="mt-4">

                                    <h5>Total de {idreporte_evidencia_visual.anexos.length} imagen(es) de evidencia </h5>


                                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                      {idreporte_evidencia_visual.anexos.map((foto:any, index:number) => (
                                        <div key={index} className="col">
                                          <div className="card h-100">
                                            {
                                              <img src={SERVER_+`reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${ Math.random().toString(36).substr(2, 9) }`} alt={foto.uuid} className="card-img-top" style={{ objectFit: 'cover', height: 'auto' }} />
                                            }
                                            <div className="card-body">
                                              <textarea 
                                                className="form-control mb-2" 
                                                value={foto.descripcion} 
                                                onChange={(e) => {
                                                  let r = {...idreporte_evidencia_visual}
                                                  r.anexos[index].descripcion = e.target.value.toUpperCase()
                                                  setReporteVisualHecho(r)
                                                }}
                                                placeholder="Descripción de la foto"
                                              ></textarea>
                                              <button className="btn btn-success me-2" onClick={(e:any) => { actualizarAnexo(e,foto.uuid, foto.descripcion) }}>Guardar</button>
                                              <button className="btn btn-danger me-2" onClick={(e:any) => eliminarAnexo(e,foto.uuid)}>Eliminar</button>
                                              <button className="btn btn-warning" onClick={(e:any) => girarFoto(e,foto.uuid)}>Girar</button>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>

                                    </div>


                                  </React.Fragment>
                                )
                            }




                        </Tab>
                      </Tabs>
                    </React.Fragment>
                )
            }

            {
              !idreporte_evidencia_visual && (
                  <>


                        <Formik
                          validationSchema={schema}
                          onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                          initialValues={{

                                folio : params.registro.folio,
                                descripcion : "",

                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                          }) => (
                            <Form id='my-form' noValidate onSubmit={handleSubmit}>

                                <div className="alert alert-warning" role="alert">
                                  <p className="nm">🚨 Antes de Empezar a cargar la imágenes brinda una descripción o comentarios acerca del Reporte Visual</p>
                                </div>

                                <Form.Group className="mb-3" controlId="descripcion">
                                  <Form.Label>Descripción del Reporte Visual</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    required
                                    rows={5}
                                    name="descripcion"
                                    value={values.descripcion}
                                    onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                                    isInvalid={!!errors.descripcion}
                                  />
                                  <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
                                </Form.Group>


                              <div className="d-grid gap-2 d-md-block">
                                <button className="btn btn-danger" type="button">Cancelar</button>
                                {' '}
                                <button className="btn btn-success" form='my-form' type="submit">Crear Reporte</button>
                              </div>



                            </Form>
                          )}
                        </Formik>

                  </>
                )
            }



          {
            // JSON.stringify(registro?.idreporte_evidencia_visual)
            // JSON.stringify(idreporte_evidencia_visual)
          }
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteEvidenciasVisuales;