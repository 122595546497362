import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'






const ModalAgregarElementoPresupuesto = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, tipo_elemento, actividades, setActividades, editar, indice, sumarSubtotales } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          descripcion : yup.string().required(mensajes_validacion_generico),
            costo : yup.number().required(mensajes_validacion_generico),
            tipo:yup.number(),
            cantidad: ['material','mano_obra','herramienta','servicio'].indexOf(tipo_elemento) > -1 ? yup.number().required(mensajes_validacion_generico) : yup.number(),
            idunidad_medida: ['material','mano_obra','herramienta'].indexOf(tipo_elemento) > -1 ? yup.number().required(mensajes_validacion_generico) : yup.number()
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{


          registro.precio = registro.costo * registro.cantidad

          let a : any = [] 

            if(editar){
              // valores_edicion = { ...actividades[indice] }
                a = [...actividades]
                a[indice] = registro

            }

            if(!editar){
                a = [...actividades]
                a.push( registro )
            }

          setActividades(a)
          handleClose()
          sumarSubtotales()

        // await dialog.open({
        //   accept: 'Si',
        //   cancel: 'Espera',
        //   dialogClass: '',
        //   message: '¿Estás seguro?',
        //   target: event.target,
        //   template: ''
        // })
        // let dialogo : any = await dialog.waitForUser()
        // if(dialogo){
        //   // agregarActividad(0)



          
        // }
       
  }







  React.useEffect(() => {
    cargarTipo_unidad_medida()
  }, []);



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }



 const valoresIniciales = () => {

        let t
          if(tipo_elemento==='material') t = 0
          if(tipo_elemento==='mano_obra') t = 1
          if(tipo_elemento==='herramienta') t = 2
          if(tipo_elemento==='servicio') t = 3
          // if(tipo_elemento===4) l = 

      let def : any = {
            descripcion : "",
            // precio : null,

            idarticulo:null,
            costo:"",
            indispensable:false,
            urgente:false,
            tipo: t,
            cantidad:"",
            idunidad_medida:""

      }


      let valores_edicion = null
      if(editar){
        valores_edicion = { ...actividades[indice] }
      }

      return !editar ? def : valores_edicion

 }

 let tipoElemento = () => {
          let t
          if(tipo_elemento==='material') t = 0
          if(tipo_elemento==='mano_obra') t = 1
          if(tipo_elemento==='herramienta') t = 2
          if(tipo_elemento==='servicio') t = 3

      return t
 }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Elemento</Modal.Title>
        </Modal.Header>


        { tipo_elemento === 'servicio' && (<div style={{background:'#fff8a3',padding:'5px 10px',textAlign:'center'}}> SERVICIO </div>) }
        { tipo_elemento === 'herramienta' && (<div style={{background:'#ddb3dc',padding:'5px 10px',textAlign:'center'}}> HERRAMIENTA </div>) }
        { tipo_elemento === 'mano_obra' && (<div style={{background:'#9ac7ac',padding:'5px 10px',textAlign:'center'}}> MANO DE OBRA </div>) }
        { tipo_elemento === 'material' && (<div style={{background:'#dee2e6',padding:'5px 10px',textAlign:'center'}}> MATERIAL </div>) }

        <Modal.Body>
          {
            // JSON.stringify(actividades[indice])
          }

          {
            // JSON.stringify(schema)
          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={valoresIniciales()}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form-elemento' noValidate onSubmit={handleSubmit}>

{
  // JSON.stringify(values)
}

            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripcion del Elemento</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                rows={4}
                value={values.descripcion}
                onBlur={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                isInvalid={!!errors.descripcion}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="costo">
              <Form.Label>Costo</Form.Label>
              <Form.Control
                required
                name="costo"
                value={values.costo}
                onChange={(e:any)=>{ setFieldValue('costo', e.target.value ); }}
                isInvalid={!!errors.costo}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.costo}</>} </Form.Control.Feedback>
            </Form.Group>

{/*            <Form.Group className="mb-3" controlId="precio">
              <Form.Label>Precio</Form.Label>
              <Form.Control
                required
                name="precio"
                value={values.precio}
                onChange={(e)=>{ setFieldValue('precio', e.target.value ) }}
                isInvalid={!!errors.precio}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.precio}</>} </Form.Control.Feedback>
            </Form.Group>*/}


            {

              ['material','mano_obra','herramienta','servicio'].indexOf(tipo_elemento) > -1 && (

                      <Form.Group className="mb-3" controlId="cantidad">
                        <Form.Label>Cantidad / Unidades</Form.Label>
                        <Form.Control
                          required
                          name="cantidad"
                          value={values.cantidad}
                          onChange={(e:any)=>{ setFieldValue('cantidad', e.target.value ); }}
                          isInvalid={!!errors.cantidad}
                        />
                        <Form.Control.Feedback type="invalid"> {<>{errors.cantidad}</>} </Form.Control.Feedback>
                      </Form.Group>

                )
            }


            {

              ['material','mano_obra','herramienta'].indexOf(tipo_elemento) > -1 && (

                     <Form.Group className="mb-3" controlId="idunidad_medida">
                      <Form.Label>Unidad de Medida del Elemento</Form.Label>
                      <Form.Select required name="idunidad_medida" value={values.idunidad_medida} onChange={(e)=>{ setFieldValue('idunidad_medida', e.target.value ) }} isInvalid={!!errors.idunidad_medida}>
                        <option value="">SELECCIONA UN TIPO</option>
                          {
                            tipo_unidad_medida.map( (unidad_medida:any,indice_unidad_medida:number) =>
                              <React.Fragment key={ indice_unidad_medida }>
                                {
                                  unidad_medida.tipo === tipoElemento() && (<option selected={unidad_medida.id===values.idunidad_medida} value={unidad_medida.id}>{unidad_medida.unidad_medida}</option>)
                                }
                                {
                                  // ['material','mano_obra','herramienta'].indexOf(tipo_elemento) > -1 && (<option selected={unidad_medida.id===values.idunidad_medida} value={unidad_medida.id}>{unidad_medida.unidad_medida}</option>)
                                }
                              </React.Fragment>
                            )
                          }
                      </Form.Select>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid"> {<>{errors.idunidad_medida}</>} </Form.Control.Feedback>
                    </Form.Group>
                      
                )
            }







        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form-elemento' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>

      </Modal>
    </>
  );
}


export default ModalAgregarElementoPresupuesto;