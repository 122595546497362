export enum ROLE {
  ADMIN = "ROLE_ADMIN",
  ROLE_CEO = "ROLE_CEO",
  ROLE_CLIENTE = "ROLE_CLIENTE",
  ROLE_BOUTIQUE = "ROLE_BOUTIQUE",
  
  ROLE_RESPONSABLE_OPERACION = "ROLE_RESPONSABLE_OPERACION",

  
  ROLE_TECNICO = "ROLE_TECNICO",
  ROLE_LIMPIEZA = "ROLE_LIMPIEZA",
  ROLE_CONSERVACION = "ROLE_CONSERVACION",

  ROLE_RESPONSABLE_TECNICOS = "ROLE_RESPONSABLE_TECNICOS",
  ROLE_RESPONSABLE_LIMPIEZA = "ROLE_RESPONSABLE_LIMPIEZA",

  
  ROLE_CONTABILIDAD = "ROLE_CONTABILIDAD",

  
  ROLE_RECURSOS_HUMANOS = "ROLE_RECURSOS_HUMANOS",


 

  
  ROLE_RESPONSABLE_PRESUPUESTO = "ROLE_RESPONSABLE_PRESUPUESTO",



ROLE_SISTEMAS = "ROLE_SISTEMAS",
ROLE_RECURSOS_MATERIALES = "ROLE_RECURSOS_MATERIALES",
ROLE_ATENCION_CLIENTES = "ROLE_ATENCION_CLIENTES",


ROLE_MARKETING = "ROLE_MARKETING",
ROLE_RECLUTAMIENTO = "ROLE_RECLUTAMIENTO",
ROLE_JEFE_ADMIN = "ROLE_JEFE_ADMIN",


ROLE_COMPROBACIONES = "ROLE_COMPROBACIONES",


ROLE_COMPRAS = "ROLE_COMPRAS",


RESIDENTE_OBRA = "RESIDENTE_OBRA",
AUXILIAR_OBRA = "AUXILIAR_OBRA",
ROLE_OBRA = "ROLE_OBRA",
ROLE_SHEI = "ROLE_SHEI",

}


