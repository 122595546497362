import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { formatearNumero, condicionesPago, calcularTiempoTranscurrido, tipoActividadLabel, ordenarPorTipo, labelUnidadMedida, tipoPresupuesto } from '../../services/funcionesPublicas'

import Dialog from '../../services/Dialogs'

import moment from 'moment'

import { SERVER_ } from  '../../config';


import { rol } from '../../services/rol'


const ModalDetallePresupuesto = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, presupuesto } = params

  const dialog = new Dialog()

const confirmarreporteEntradaSalida = async(e:any ) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`presupuesto/enviar_valiacion/${presupuesto.uuid_presupuesto}`, {} )
          if(res.data){
            toast.success('Se actualizo el estatus del Presupuesto')
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas  ')
    }

  }

  React.useEffect(() => {
    sumarSubtotales()
    cargarTipo_unidad_medida()
  }, []);

 


  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = () =>{
    let s = 0
    // console.log(presupuesto.material_actividades)
    for(let actividad of presupuesto.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }



  return (
    <>
      <Modal show={show} size={`${ (presupuesto.idestatus_presupuesto.id > 3 ? 'xl' : 'xl') }`} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Presupuesto</Modal.Title>
        </Modal.Header>
          {
            // JSON.stringify(presupuesto.idestatus_presupuesto.id)
          }

          <div className={`text-center ${presupuesto.tipo_presupuesto}`} style={{padding: '10px 0'}}  >
            <p className="nm"><small><b>{tipoPresupuesto(presupuesto.tipo_presupuesto)}</b></small></p>
          </div>


        <Modal.Body>

                    <h6 className="mt--4">Realizó:</h6>

                    <div className="row">
                      <div className="col-sm-6">

                          <p className="nm"><b>{presupuesto.idusuario.nombres}</b> {presupuesto.idusuario.paterno} {presupuesto.idusuario.materno}</p>
                          <small style={{fontSize:10}}>{rol(presupuesto.idusuario.rol)}</small>


                      </div>
                      <div className="col-sm-2">
                        <small>Celular</small>
                        <h6>{presupuesto.idusuario.celular}</h6>
                      </div>
                      <div className="col-sm-4">

                        <small>Correo Electrónico</small>
                        <h6>{presupuesto.idusuario.email}</h6>
 
                      </div>
                    </div>

          {
            [1,2].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (

                <>



          {
            presupuesto.idboutique && (
                <React.Fragment>

                    <div className="row">
                      <div className="col-sm-4">
                          <h5 className="mt-2">Boutique</h5>
                          <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                          {
                            presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Solicitado por la Boutique</p>)
                          }

                          {
                            // JSON.stringify(presupuesto.idboutique.idsubarea)
                          }
                      </div>
                      <div className="col-sm-2">
                          <h5 className="mt-2">Folio</h5>
                          <p>{presupuesto.folio}</p>
                      </div>
                      <div className="col-sm-2">
                          {
                            presupuesto?.visita &&
                              <>
                                <h5 className="mt-2">Folio de Servicio</h5>
                                <p>{presupuesto?.visita ? presupuesto.visita?.folio : 'En Proceso'}</p>
                              </> 
                            }
                      </div>
                      <div className="col-sm-4">
                          {
                            presupuesto?.visita &&
                              <>
                                <h5 className="mt-2">Periodo</h5>
                                <p>DEL {moment(presupuesto?.visita?.idcalendario?.fecha_inicio).format('YYYY/MMM/DD').toUpperCase().replace('.','')} AL {moment(presupuesto?.visita?.idcalendario?.fecha_fin).format('YYYY/MMM/DD').toUpperCase().replace('.','')}</p>
                                {
                                  // JSON.stringify(presupuesto?.visita?.idcalendario)
                                }
                              </> 
                            }
                      </div>
                    </div>

                </React.Fragment>
              )
          }






                    <h5 className="mt-2">Descripción General</h5>
                    <p><small>{presupuesto.descripcion}</small></p>



                    {
                      presupuesto.actividades_propuestas.length > 0 && (
                          <React.Fragment>
                                    
                             <h5 className="mt-2">Materiales solicitados</h5>
         

                            <table className="table table-sm mt-2"  >
                              <thead>
                                <tr>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Unidad</th>
                                  <th scope="col">Cantidad</th> 
                                </tr>
                              </thead>
                              <tbody>
                              {
                                [...presupuesto.actividades_propuestas].sort(ordenarPorTipo).map((material:any,index:number)=>


                                <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                  <td>
                                    <p className="nm"><small>{material.descripcion}</small></p>
                                  </td>
                                  <td className="v" style={{width:180}}>
                                    <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                                  </td>
                                  <td className="v" style={{width:80}}>
                                    <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                  </td>  
                                </tr>

                                  )
                              }


                              </tbody>
                            </table>
                            
                          </React.Fragment>
                        )
                    }



                    <div className="row">



                      {
                        (["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1 && ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) === -1) &&(

                            <React.Fragment>
                                <div className="col-sm-6">
                                    <h6 className="mt-">Tiempo Estimado de la Obra</h6>
                                    <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>
                                </div>
                            </React.Fragment>

                        )}



                      <div className="col-sm-6">
                          <h6 className="mt-">Fecha de Creación</h6>
                          <p className="nm">{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</p>
                          <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>
                      </div>
                    </div>





                </>

              )
          }

          {
            [3,4,5,6,7,8,9,11].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (

                <>

        <div className="row">
          <div className="col-sm-4">

          <h6 className="mt-2">Folio *</h6>
          <p>{presupuesto.folio}</p>

          {presupuesto.idboutique&&(
              <React.Fragment>
                <h6 className="mt-2">Boutique</h6>
                <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                  {
                    presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> <small>Solicitado por la Boutique</small></p>)
                  }
              </React.Fragment>
            )}



          <h6 className="mt-2">Fecha de Creación</h6>
          <p className="nm">{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</p>
          <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>

          <h5 className="mt-2">Descripción General</h5>
          <p className="nm" style={{textAlign:'justify'}}><small>{presupuesto.descripcion}</small></p>







                    {
                      presupuesto.actividades_propuestas.length > 0 && (
                          <React.Fragment>
                                    
                             <h5 className="mt-2">Materiales solicitados</h5>


                            <table className="table table-sm mt-2" style={{fontSize:12}}>
                              <thead>
                                <tr>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Unidad</th>
                                  <th scope="col">Cantidad</th> 
                                </tr>
                              </thead>
                              <tbody>
                              {
                                [...presupuesto.actividades_propuestas].sort(ordenarPorTipo).map((material:any,index:number)=>


                                <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                  <td>
                                    <p className="nm"><small>{material.descripcion}</small></p>
                                  </td>
                                  <td className="v" style={{width:100}}>
                                    <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                                  </td>
                                  <td className="v" style={{width:40}}>
                                    <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                  </td>  
                                </tr>

                                  )
                              }


                              </tbody>
                            </table>

                            
                          </React.Fragment>
                        )
                    }







          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION","PRESUPUESTO_INSUMOS"].indexOf(presupuesto.tipo_presupuesto) === -1 &&(
                <React.Fragment>
                    {
                      !presupuesto.entra_iguala && (
                          <React.Fragment>
                              <h6 className="mt-2">Condiciones de Pago</h6>
                              <p>{condicionesPago(presupuesto.tipo_condicion_pago)}</p>
                          </React.Fragment>
                        )
                    }
                </React.Fragment>
            )
          }


          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION","PRESUPUESTO_INSUMOS"].indexOf(presupuesto.tipo_presupuesto) === -1 &&(
                <React.Fragment>
                    {
                      !presupuesto.entra_iguala && (
                          <React.Fragment>
                              <h6 className="mt-2">Condiciones de Ejecución</h6>
                              <p><small>{presupuesto.condiciones_ejecucion||'Ninguna en particular'}</small></p>
                          </React.Fragment>
                        )
                    }
                </React.Fragment>
            )
          }


          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION","PRESUPUESTO_INSUMOS"].indexOf(presupuesto.tipo_presupuesto) === -1 &&(
                <React.Fragment>
                    {
                      !presupuesto.entra_iguala && (
                          <React.Fragment>
                              <h6 className="mt-2">Vigencia de Precios</h6>
                              <p>{presupuesto.vigencia_dias_precios}</p>
                          </React.Fragment>
                        )
                    }
                </React.Fragment>
            )
          }




          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION","PRESUPUESTO_INSUMOS"].indexOf(presupuesto.tipo_presupuesto) === -1 &&(

                <React.Fragment>
                      {
                        !presupuesto.entra_iguala && (
                            <React.Fragment>
                                <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
                                <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>
                            </React.Fragment>
                          )
                      }

                </React.Fragment>

            )}


          </div>
          <div className="col-sm-8">
            {
              // JSON.stringify(presupuesto.visita)
            }






          {
            presupuesto.visita && (
              <React.Fragment>

                  <h5 className="mt-2">Actividades realizadas durante el servicio</h5>
                  <ol>
                    { presupuesto.visita.actividades_reporte.map( (actividad:any,indice_actividad:number)=><li key={indice_actividad}><small>{actividad}</small></li>)}
                  </ol>
                
              </React.Fragment>
            )
          }


          {
            presupuesto.entra_iguala ? 'SI' : '💰 Este presupuesto NO entra en la iguala. Se cobrara por separado'
          }


                  <h5 className="mt-2">Explosión de precios unitarios</h5>

                  {
                    presupuesto.entra_iguala && (
                        <React.Fragment>
                          SI ENTRA
                        </React.Fragment>
                      )
                  }

                  <table className="table table-sm" style={{fontSize:12}}>
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Unidad</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">P.U.</th>
                        <th scope="col">Costo</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      [...presupuesto.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                      <tr>
                        <td className="v" style={{width:120}}>
                          <p className="nm">
                          {
                            // JSON.stringify(material.idunidad_medida)
                          }
                          <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                          </p>
                        </td>
                        <td>
                          <p className="nm"><small>{material.descripcion}</small></p>
                        </td>
                        <td className="v" style={{width:120}}>
                          {material.idunidad_medida&&(<p className="nm"><small>{material.idunidad_medida.unidad_medida}</small></p>)}
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat(material.costo)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat((material.costo)||0)*(parseFloat(material.cantidad)||0))}</p>
                        </td>
                      </tr>

                        )
                    }



                      <tr className="table-subs">
                        <td className="v" style={{width:100}} colSpan={5}>
                          <p className="nm text-right"><b>SUB TOTAL:</b></p>
                        </td>
                        <td className="v" style={{width:100}}>
                          <p className="nm text-right">$ {formatearNumero(suma_subtotal||0)}</p>
                        </td>
                      </tr>

          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1 &&(
                <React.Fragment>
                    {
                      !presupuesto.entra_iguala && (
                          <React.Fragment>

                            <tr className="table-subs">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>SERVICIO:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero(presupuesto.costo_servicio||0)}</p>
                              </td>
                            </tr>
                            <tr className="table-subs">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>SERVICIO + SUB TOTAL:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero(presupuesto.costo_servicio + (suma_subtotal||0))}</p>
                              </td>
                            </tr>
                            <tr className="table-impuestos">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>IMPUESTOS:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero((presupuesto.costo_servicio + (suma_subtotal||0))*.16)}</p>
                              </td>
                            </tr>
                            <tr className="table-total">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>TOTAL:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero( ((presupuesto.costo_servicio||0) + (suma_subtotal||0)) + (((presupuesto.costo_servicio||0) + (suma_subtotal||0))*.16)  )}</p>
                              </td>
                            </tr>


                          </React.Fragment>
                        )
                    }
                </React.Fragment>
            )
          }





                    </tbody>
                  </table>


          </div>
        </div>



          
                </>

              )
          }



        </Modal.Body>


      {
        [1].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={confirmarreporteEntradaSalida} type="button">Mandar a Validación </Button>
        </Modal.Footer>


          )
      }

      </Modal>
    </>
  );
}


export default ModalDetallePresupuesto;