export const rol = (rol:string)=>{
    let r = '----'
    if(rol === 'ROLE_ADMIN') r = 'ADMINISTRADOR'
    if(rol === 'ROLE_CEO') r = 'DIRECTOR EJECUTIVO'
    if(rol === 'ROLE_RESPONSABLE_OPERACION') r = 'RESPONSABLE OPERACIÓN'
        
    if(rol === 'ROLE_TECNICO') r = 'TÉCNICO DE MANTENIMIENTO'
    if(rol === 'ROLE_CONSERVACION') r = 'TÉCNICO DE CONSERVACIÓN'
    if(rol === 'ROLE_RESPONSABLE_TECNICOS') r = 'RESPONSABLE DE MANTENIMIENTO'
    if(rol === 'ROLE_LIMPIEZA') r = 'OPERADOR DE LIMPIEZA'
    if(rol === 'ROLE_RESPONSABLE_LIMPIEZA') r = 'RESPONSABLE DE OPERADORES'

    if(rol === 'ROLE_CLIENTE') r = 'CLIENTE'
    if(rol === 'ROLE_BOUTIQUE') r = 'RESPONSABLE DE BOUTIQUE'



    if(rol === 'ROLE_CONTABILIDAD') r = 'FINANZAS Y CONTABILIDAD'
    if(rol === 'ROLE_RECURSOS_HUMANOS') r = 'RECURSOS HUMANOS'
    // if(rol === 'ROLE_OBRA') r = 'ROLE_OBRA'
    if(rol === 'ROLE_SISTEMAS') r = 'SISTEMAS'
    if(rol === 'ROLE_RESPONSABLE_PRESUPUESTO') r = 'RESPONSABLE PRESUPUESTO'
    if(rol === 'ROLE_RECURSOS_MATERIALES') r = 'RECURSOS MATERIALES'
    if(rol === 'ROLE_ATENCION_CLIENTES') r = 'ATENCION CLIENTES'

        
    if(rol === 'ROLE_ALMACEN') r = 'ALMACÉN Y HERRAMIENTAS'


    if(rol === 'ROLE_MARKETING') r = 'MARKETING'
    if(rol === 'ROLE_RECLUTAMIENTO') r = 'R.H. RECLUTAMIENTO'
    if(rol === 'ROLE_JEFE_ADMIN') r = 'JEFE ADMINISTRATIVO'






    if(rol === 'ROLE_COMPROBACIONES') r = 'COMPROBACIONES DE GASTOS'
    // if(rol === 'ROLE_JEFE_ADMIN') r = 'JEFE ADMINISTRATIVO'
    // if(rol === 'ROLE_JEFE_ADMIN') r = 'JEFE ADMINISTRATIVO'
    // if(rol === 'ROLE_JEFE_ADMIN') r = 'JEFE ADMINISTRATIVO'



    if(rol === 'ROLE_SHEI') r = 'ROLE_SHEI'

    if(rol === 'RESIDENTE_OBRA') r = "RESIDENTE DE OBRA"
    if(rol === 'ROLE_OBRA') r = "TÉCNICO DE OBRA"
    if(rol === 'AUXILIAR_OBRA') r = "AUXILIAR DE OBRA"
    if(rol === 'ELECTRICO') r = "ELÉCTRICO"
    if(rol === 'AYUDANTE_ELECTRICO') r = "AYUDANTE DE ELÉCTRICO"
    if(rol === 'TABLAROQUERO') r = "TABLAROQUERO"



    if(rol === 'ROLE_COMPRAS') r = "COMPRAS DE REQUISICIONES"


    return r
}