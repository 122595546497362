import React, { useState, useEffect } from 'react'

import { Link, Route, Routes, useLocation } from 'react-router-dom'


import { Container, Navbar, Nav , NavDropdown } from 'react-bootstrap'


import { PrivateRoute } from './PrivateRoute'

import NotFound from './pages/NotFound'
import Login from './pages/Login'
import Profile from './pages/Profile'

import FAQ from './pages/faq/FAQ'


import Verificacion from './pages/Verificacion'



import { ROLE } from './features/auth/role'

import AuthService from './features/auth/auth.service'





import Circulares from './pages/Circulares/Circulares'





import RecursosHumanos from './pages/Recursos_humanos/RecursosHumanos'

import Incidencias from './pages/Incidencias/Incidencias'







import Vehiculos from './pages/Vehiculos/Vehiculos'









import Inventario from './pages/Inventario/Inventario'


import Herramientas from './pages/Operarios/Herramientas'






// import Departamentos from './pages/Departamentos/Departamentos'


// import Incidencias from './pages/Incidencias/Incidencias'


import Administracion from './pages/Administracion/Administracion'
import EquiposCriticos from './pages/Administracion/EquiposCriticos'
import Zonas from './pages/Administracion/Zonas'
import Subzonas from './pages/Administracion/Subzonas'




import Limpieza from './pages/Operacion/Limpieza'
import Mantenimiento from './pages/Operacion/Mantenimiento'

import Operacion from './pages/Operacion/Operacion'


import CalendarioFull from './pages/Calendario/CalendarioFull'
import CalendarioPreview from './pages/Calendario/CalendarioPreview'


// import Recepcion from './pages/Recepcion/Recepcion'
// import MisOficios from './pages/Recepcion/MisOficios'


import CorporativoClientes from './pages/Clientes/CorporativoClientes'

import Clientes from './pages/Clientes/Clientes'
import Cliente from './pages/Clientes/Cliente'

import CalendarioPreviewCliente from './pages/Clientes/CalendarioPreviewCliente'
import CalendarioServicioCliente from './pages/Clientes/CalendarioServicioCliente'

import PanelCliente from './pages/Clientes/PanelCliente'



import Reporte_servicios from './pages/Responsables/Reporte_servicios'



import Contabilidad from './pages/Contabilidad/Contabilidad'
import CobranzaServicios from './pages/Contabilidad/CobranzaServicios'



import ProgramacionPagos from './pages/Programacion_pagos/ProgramacionPagos'




import Requisiciones from './pages/Requisiciones/Requisiciones'
import ComprobacionesEgresos from './pages/Requisiciones/ComprobacionesEgresos'
import PagoRequisiciones from './pages/Requisiciones/PagoRequisiciones'

import ComprasRequisiciones from './pages/Requisiciones/ComprasRequisiciones'



import MisRequisiciones from './pages/Requisiciones/MisRequisiciones'
import MiCalendario from './pages/Operarios/MiCalendario'
import Tareas from './pages/Operarios/Tareas'
import ReporteTareas from './pages/Operarios/ReporteTareas'



import MisTickets from './pages/Operarios/MisTickets'
import Tickets from './pages/Tickets/Tickets'



import Proveedores from './pages/Proveedores/Proveedores'
import ListaProveedores from './pages/Proveedores/ListaProveedores'
import ProveedoresArticulos from './pages/Proveedores/ProveedoresArticulos'



import Presupuestos from './pages/Presupuestos/Presupuestos'
import PresupuestosAdministracion from './pages/Presupuestos/PresupuestosAdministracion'
import PresupuestosTecnico from './pages/Presupuestos/PresupuestosTecnico'
import PresupuestosBoutiques from './pages/Presupuestos/PresupuestosBoutiques'
import Refaccionamiento from './pages/Presupuestos/Refaccionamiento'

















import Reportes_analiticas from './pages/Reportes/Reportes_analiticas'









import Cobranza from './pages/Cobranza/Cobranza'

import Pagos from './pages/Pagos/Pagos'


import './App.scss';


function BootHeader() {

	const user = AuthService.getCurrentUser()

	const isAuthenticated = AuthService.selectIsAuthenticated
	let location = useLocation();

	let activo_perfil = location.pathname === '/profile' ? 'active' : ''
	let activo_clientes = location.pathname === '/clientes' ? 'active' : ''
	let activo_cliente = location.pathname === '/mi_panel' ? 'active' : ''
	let activo_recursos_humanos = location.pathname === '/recursos_humanos' ? 'active' : ''
	let activo_refaccionamiento = location.pathname === '/refaccionamiento' ? 'active' : ''
	// let activo_rh = location.pathname === '/rh' ? 'active' : ''
	// let activo_oficios = location.pathname === '/oficios' ? 'active' : ''
	// let activo_recepcion_oficios = location.pathname === '/recepcion_oficios' ? 'active' : ''
	// let activo_usuarios = location.pathname === '/usuarios' ? 'active' : ''

	let activo_contabilidad = location.pathname.indexOf('contabilidad') > -1 ? 'active' : ''
	let activo_cobranza_servicios = location.pathname.indexOf('cobro_servicios') > -1 ? 'active' : ''

	let activo_pagos = location.pathname.indexOf('pagos') > -1 ? 'active' : ''

	let activo_tickets = location.pathname.indexOf('tickets') > -1 ? 'active' : ''


	let activo_comprobaciones = location.pathname.indexOf('comprobaciones_egresos') > -1 ? 'active' : ''
	let activo_deposito_egresos = location.pathname.indexOf('deposito_egresos') > -1 ? 'active' : ''



	const [ ROLE_ADMIN, setAdmin ] = useState(false)
	const [ ROLE_CEO, setCEO ] = useState(false)
	const [ ROLE_CLIENTE, setCliente ] = useState(false)
	const [ ROLE_BOUTIQUE, setBoutique ] = useState(false)

	const [ ROLE_RESPONSABLE_OPERACION, setResponsableOperacion ] = useState(false)

	const [ ROLE_TECNICO, setTecnico ] = useState(false)
	const [ ROLE_LIMPIEZA, setLimpieza ] = useState(false)
	const [ ROLE_CONSERVACION, setConservacion ] = useState(false)

	const [ ROLE_RESPONSABLE_TECNICOS, setResponsableTecnico ] = useState(false)
	const [ ROLE_RESPONSABLE_LIMPIEZA, setResponsableLimpieza ] = useState(false)
	// const [ JEFE_DEPARTAMENTO, setJefeDepartamento ] = useState(false)
	// const [ PERSONAL, setPersonal ] = useState(false)
	// const [ DEPTO_MOVIMIENTOS, setMovimientos ] = useState<boolean>(false)
	// const [ DEPTO_NOMINA, setNomina ] = useState<boolean>(false)
	// const [ DEPTO_RETARDOS, setRetardos ] = useState<boolean>(false)
	// const [ JEFE_DEPTO, setJefe ] = useState<boolean>(false)
	// const [ RECEPCION, setRecepcion ] = useState<boolean>(false)

  const [ ROLE_CONTABILIDAD, setContabilidad ] = useState(false)
  const [ ROLE_RECURSOS_HUMANOS, setRH ] = useState(false)

  const [ ROLE_RECURSOS_MATERIALES, setAlmacen ] = useState(false)
  const [ ROLE_RESPONSABLE_PRESUPUESTO, setResponsablePresupuesto ] = useState(false)

  const [ ROLE_ATENCION_CLIENTES, setAtencionClientes ] = useState(false)


  const [ ROLE_COMPROBACIONES, setComprobaciones ] = useState(false)
  const [ ROLE_COMPRAS, setCompras ] = useState(false)


  const [ ROLE_MARKETING, setMarketing ] = useState(false)
  const [ ROLE_RECLUTAMIENTO, setReclutamiento ] = useState(false)
  const [ ROLE_JEFE_ADMIN, setJefeAdmin ] = useState(false)



  const [ RESIDENTE_OBRA, setResidenteObra ] = useState(false)
  const [ AUXILIAR_OBRA, setAuxiliarObra ] = useState(false)
  const [ ROLE_OBRA, setObra ] = useState(false)
  const [ ROLE_SHEI, setShei ] = useState(false)




 
	useEffect(() => {

	  if(user.rol === "ROLE_ADMIN") setAdmin(true)
	  if(user.rol === "ROLE_CEO") setCEO(true)
	  if(user.rol === "ROLE_CLIENTE") setCliente(true)
	  if(user.rol === "ROLE_BOUTIQUE") setBoutique(true)

	  if(user.rol === "ROLE_RESPONSABLE_OPERACION") setResponsableOperacion(true)

	  if(user.rol === "ROLE_TECNICO") setTecnico(true)
	  if(user.rol === "ROLE_LIMPIEZA") setLimpieza(true)
	  if(user.rol === "ROLE_CONSERVACION") setConservacion(true)


	  if(user.rol === "ROLE_RESPONSABLE_TECNICOS") setResponsableTecnico(true)
	  if(user.rol === "ROLE_RESPONSABLE_LIMPIEZA") setResponsableLimpieza(true)


      if(user.rol === "ROLE_CONTABILIDAD") setContabilidad(true)

      if(user.rol === "ROLE_RECURSOS_HUMANOS") setRH(true)

      if(user.rol === "ROLE_RECURSOS_MATERIALES") setAlmacen(true)

      if(user.rol === "ROLE_ATENCION_CLIENTES") setAtencionClientes(true)

      if(user.rol === "ROLE_RESPONSABLE_PRESUPUESTO") setResponsablePresupuesto(true)


      if(user.rol === "ROLE_COMPROBACIONES") setComprobaciones(true)
      if(user.rol === "ROLE_COMPRAS") setCompras(true)



      if(user.rol === "ROLE_MARKETING") setMarketing(true)
      if(user.rol === "ROLE_RECLUTAMIENTO") setReclutamiento(true)
      if(user.rol === "ROLE_JEFE_ADMIN") setJefeAdmin(true)

  
      if(user.rol === "RESIDENTE_OBRA") setResidenteObra(true)
      if(user.rol === "AUXILIAR_OBRA") setAuxiliarObra(true)
      if(user.rol === "ROLE_OBRA") setObra(true)
      if(user.rol === "ROLE_SHEI") setShei(true)




	},[ 
				ROLE_ADMIN, 
				ROLE_CEO, 
				ROLE_CLIENTE,  
				ROLE_BOUTIQUE,  

				ROLE_RESPONSABLE_OPERACION,  

				ROLE_TECNICO,  
				ROLE_LIMPIEZA,  
				ROLE_CONSERVACION,  

				ROLE_RESPONSABLE_TECNICOS,  
				ROLE_RESPONSABLE_LIMPIEZA,  

  	    ROLE_CONTABILIDAD , 

  	    ROLE_RECURSOS_HUMANOS , 

  	    ROLE_RECURSOS_MATERIALES , 

  	    ROLE_ATENCION_CLIENTES , 

  	    ROLE_RESPONSABLE_PRESUPUESTO , 

  	    ROLE_COMPROBACIONES , 
  	    ROLE_COMPRAS , 

				ROLE_MARKETING,
				ROLE_RECLUTAMIENTO,
				ROLE_JEFE_ADMIN,


				RESIDENTE_OBRA,
				AUXILIAR_OBRA,
				ROLE_OBRA,
				ROLE_SHEI,

				user]);



	const imagenNavBar = () =>{

		let img = `/img/selin_navbar.png`
		if(["ROLE_LIMPIEZA","ROLE_TECNICO","ROLE_CONSERVACION"].indexOf(user.rol) > -1) img = `/img/selin_navbar.png`
		if(["ROLE_CLIENTE","ROLE_BOUTIQUE"].indexOf(user.rol) > -1) img = `/img/selin_navbar_clientes.png`
		if(["ROLE_CLIENTE","ROLE_BOUTIQUE"].indexOf(user.rol) > -1) img = `/img/selin_navbar_clientes.png`
		if(["ROLE_RESPONSABLE_CONSERVACION","ROLE_RESPONSABLE_LIMPIEZA","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_OPERACION","ROLE_RECURSOS_MATERIALES"].indexOf(user.rol) > -1) img = `/img/selin_navbar_responsables.png`

		return img

// 'ROLE_ADMIN'
// 'ROLE_AUXILIAR_OPERACIONES'
// ''
// ''
// ''
// 'ROLE_FACTURACION'
// ''
// 'ROLE_NOMINA'
// 'ROLE_OBRA'
// ''
// ''
// ''
// ''
// 'ROLE_SISTEMAS'
// 'ROLE_SUPERVISOR_TECNICOS'
// ''


	}


  return (
    <React.Fragment>

	    { 
	    	isAuthenticated && ( 
						    <Navbar collapseOnSelect expand="lg" bg="dark" className={user.rol} variant="dark">
						      <Container fluid>
						        <Navbar.Brand><h4 className="nm"></h4></Navbar.Brand>

					          <Navbar.Brand href="#home">
					            <img
					              alt=""
					              src={ imagenNavBar() }
					              height="25"
					              className="d-inline-block align-top"
					            />{' '}
					          </Navbar.Brand>


						        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
						        <Navbar.Collapse id="responsive-navbar-nav">
						          <Nav className="me-auto" defaultActiveKey={location.pathname}>


		                 { ( 
		                 			ROLE_ADMIN || 
		                 			ROLE_CEO || 
		                 			ROLE_CLIENTE || 
		                 			ROLE_BOUTIQUE || 
		                 			ROLE_RESPONSABLE_OPERACION || 
		                 			ROLE_TECNICO  || 
		                 			ROLE_LIMPIEZA  || 

		                 			ROLE_RESPONSABLE_TECNICOS  || 
		                 			ROLE_RESPONSABLE_LIMPIEZA   || 
		                 			
		                 			ROLE_CONSERVACION || 
		                 			ROLE_CONTABILIDAD ||
		                 			ROLE_RECURSOS_HUMANOS ||

		                 			ROLE_RECURSOS_MATERIALES ||

		                 			ROLE_ATENCION_CLIENTES ||

		                 			ROLE_RESPONSABLE_PRESUPUESTO ||


													ROLE_COMPROBACIONES ||

													ROLE_COMPRAS ||

													ROLE_MARKETING ||
													ROLE_RECLUTAMIENTO ||
													ROLE_JEFE_ADMIN ||

													RESIDENTE_OBRA ||
													AUXILIAR_OBRA ||
													ROLE_OBRA ||
													ROLE_SHEI 

		                 		) && (
		                 		<>
		                     <Nav.Link  href="/profile">Perfil</Nav.Link>
		                 		</>
		                 )}


		                 { 
			                 // 	( ROLE_CLIENTE || ROLE_BOUTIQUE || ROLE_TECNICO  || ROLE_LIMPIEZA  || ROLE_CONSERVACION    ) && (
			                 // 		<>
			                 //     <Nav.Link  href="/mis_tickets">Mis Tickets</Nav.Link>
			                 // 		</>
			                 // )
		                 }



 		                { ( ROLE_RESPONSABLE_OPERACION  ) && (
		                 		<>
				                  <Nav.Link  href="/operacion">Operación</Nav.Link>
				                  <Nav.Link  href="/calendario">Calendario</Nav.Link>
				                  <Nav.Link  href="/clientes">Clientes</Nav.Link>
		                 		</>
		                )}


 		                { ( ROLE_ADMIN  || ROLE_JEFE_ADMIN ) && (
		                 		<>
				                  <Nav.Link  href="/operacion">Operación</Nav.Link>
				                  <Nav.Link  href="/inventario">Inventario</Nav.Link>
				                  <Nav.Link  href="/clientes">Clientes</Nav.Link>
				                  <Nav.Link  href="/tickets">Tickets</Nav.Link>
				                  <Nav.Link  href="/calendario">Calendario</Nav.Link>
		                     <Nav.Link  href="/administracion/presupuestos">Presupuestos</Nav.Link>


		                     <Nav.Link  href="/reportes_analiticas">Reportes</Nav.Link>

		                 		</>
		                )}



 		                { ( ROLE_ATENCION_CLIENTES  ) && (
		                 		<>
				                  <Nav.Link  href="/tickets">Tickets</Nav.Link>
				                  
		                 		</>
		                )}


 		                { ( ROLE_RECURSOS_MATERIALES  ) && (
		                 		<>
				                  <Nav.Link  href="/inventario">Inventario</Nav.Link>
				                  
		                 		</>
		                )}



 		                { ( ROLE_RESPONSABLE_PRESUPUESTO  ) && (
		                 		<>
				                  <Nav.Link  href="/operacion/presupuestos">Presupuestos</Nav.Link>
				                  
		                 		</>
		                )}



 		                { ( ROLE_RESPONSABLE_TECNICOS  || ROLE_RESPONSABLE_LIMPIEZA ) && (
		                 		<>
													<Nav.Link  href="/reportes_analiticas">Reportes</Nav.Link> 
													<Nav.Link  href="/calendario">Calendario de Trabajo</Nav.Link> 
													<Nav.Link  href="/operacion">Operación</Nav.Link> 
													<Nav.Link  href="/mis_tickets">Tickets</Nav.Link> 
													<Nav.Link  href="/operacion/presupuestos">Presupuestos</Nav.Link>
													<Nav.Link  href="/tecnico/presupuestos">Mis Presupuestos</Nav.Link>
													<Nav.Link  href="/reporte_servicios">Reporte de Supervisión</Nav.Link>
													<Nav.Link  href="/corporativo_clientes">Mapa de Clientes</Nav.Link> 
		                     </>
		                 )}




 		                { ( ROLE_LIMPIEZA || ROLE_TECNICO || ROLE_CONSERVACION ) && (
 		                	<>
		                  	<Nav.Link  href="/tareas">Tareas Diarias</Nav.Link>
		                  	<Nav.Link  href="/mi_reporte">Mis Reportes</Nav.Link>
		                     <Nav.Link  href="/mis_tickets">Mis Tickets</Nav.Link>
		                  	<Nav.Link  href="/mi_calendario">Mi Calendario</Nav.Link>
		                  	<Nav.Link  href="/mis_requisiciones">Mis Requisiciones</Nav.Link>
		                  	{
		                  		// <Nav.Link  href="/mis_pagos">Mis Pagos</Nav.Link>
		                  		// <Nav.Link  href="/asuetos">Asuetos</Nav.Link>
		                  	}
 		                	</>
		                )}



 		                { (   ROLE_TECNICO  ) && (
 		                	<>
		                  	<Nav.Link  href="/tecnico/presupuestos">Presupuestos</Nav.Link>
		                  	{
		                  		// <Nav.Link  href="/mis_pagos">Mis Pagos</Nav.Link>
		                  		// <Nav.Link  href="/asuetos">Asuetos</Nav.Link>
		                  	}
 		                	</>
		                )}













            { (ROLE_CONTABILIDAD || ROLE_ADMIN || ROLE_JEFE_ADMIN) && (
              <>
                  <Link className={'nav-link ' + activo_contabilidad } to={'/contabilidad'}> Finanzas </Link>
                  <Link className={'nav-link ' + activo_cobranza_servicios } to={'/cobro_servicios'}> Cobro de Servicios </Link>
              </>
            )}











 		                 { ( ROLE_CLIENTE  ) && (
		                     <Nav.Link  href="/mi_panel">Mi Panel</Nav.Link>
		                 )}

 
 		                 { ( ROLE_ADMIN || ROLE_JEFE_ADMIN || ROLE_RECURSOS_HUMANOS || ROLE_RECLUTAMIENTO  ) && (
	 		                 	<React.Fragment>
			                     <Nav.Link  href="/recursos_humanos"> Recursos Humanos</Nav.Link>
			                     <Nav.Link  href="/incidencias"> Incidencias</Nav.Link>
		                     </React.Fragment>
		                 )}










 		                 { ( ROLE_BOUTIQUE ) && (
	 		                 	<React.Fragment>
		                     <Nav.Link  href="/mis_tickets">Mis Tickets</Nav.Link>
		                     <Nav.Link  href="/calendario/propuesta"> Visitas Programadas</Nav.Link>
		                     <Nav.Link  href="/mi_panel"> Panel de Cliente</Nav.Link>
		                     <Nav.Link  href="/cliente/refaccionamiento"> Insumos</Nav.Link>
		                     <Nav.Link  href="/circulares"> Circulares</Nav.Link>
		                     </React.Fragment>
		                 )}






		                     	



		                 { ( 
		                 			ROLE_ADMIN || 
		                 			ROLE_RESPONSABLE_OPERACION || 
		                 			ROLE_TECNICO  || 
		                 			ROLE_LIMPIEZA  || 

		                 			ROLE_RESPONSABLE_TECNICOS  || 
		                 			ROLE_RESPONSABLE_LIMPIEZA   || 
		                 			
		                 			ROLE_CONSERVACION || 
		                 			ROLE_CONTABILIDAD ||
		                 			ROLE_RECURSOS_HUMANOS ||

		                 			ROLE_RECURSOS_MATERIALES ||

		                 			ROLE_ATENCION_CLIENTES ||

		                 			ROLE_RESPONSABLE_PRESUPUESTO ||

		                 			ROLE_COMPROBACIONES ||

		                 			ROLE_COMPRAS ||


													ROLE_MARKETING ||
													ROLE_RECLUTAMIENTO ||
													ROLE_JEFE_ADMIN ||

													RESIDENTE_OBRA ||
													AUXILIAR_OBRA ||
													ROLE_OBRA ||
													ROLE_SHEI 

		                 		) && (
		                 		<>
		                     <Nav.Link  href="/mis_herramientas">Mis Herramientas</Nav.Link>

		                 		</>
		                 )}






						          </Nav>
						          <Nav>
						            <Nav.Link href="/profile">{user.nombres}</Nav.Link>
						          </Nav>
						        </Navbar.Collapse>
						      </Container>
						    </Navbar>
	    	) 
	    }


	    { 
	    	// isAuthenticated && ( 
		    //   <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
		    //     <div className="container-fluid">


		    //       <a className="navbar-brand" href="./" style={{fontSize:'20px'}}>
		    //         SELIN
		    //       </a>

		    //       <div className="collapse navbar-collapse" id="navbarNav">
		    //         <ul className="navbar-nav me-auto mb-2 mb-lg-0">

		    //             { ( ROLE_ADMIN || ROLE_CLIENTE || ROLE_BOUTIQUE || ROLE_TECNICO  || ROLE_LIMPIEZA  || ROLE_RESPONSABLE_TECNICOS  || ROLE_RESPONSABLE_LIMPIEZA  ) && (
		    //               <li className="nav-item">
		    //                 <Link className={'nav-link ' + activo_perfil } to="/profile"> Perfil </Link>
		    //               </li>
		    //             )}



 		    //             { ( ROLE_CLIENTE  ) && (
		    //               <li className="nav-item">
		    //                 <Link className={'nav-link ' + activo_cliente } to="/mi_panel"> Mi Panel </Link>
		    //               </li>
		    //             )}

 
 		    //             { ( ROLE_ADMIN  ) && (
		    //               <li className="nav-item">
		    //                 <Link className={'nav-link ' + activo_recursos_humanos } to="/recursos_humanos"> Recursos Humanos </Link>
		    //               </li>
		    //             )}

 

		    //         </ul>

		    //         <span className="navbar-text">
		    //           Usuario: <Link className="link" to="/profile"> {user?.email} </Link>
		    //         </span>

		    //       </div>

		    //     </div>
		    //   </nav>
	    	// ) 
	    }

    </React.Fragment>
  )

}

function App() {

  return (
    <>
    
      <BootHeader />

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />

        <Route path="profile" element={<PrivateRoute roles={[
        																											ROLE.ADMIN, 
        																											ROLE.ROLE_CEO,

        																											ROLE.ROLE_CLIENTE, 
        																											ROLE.ROLE_BOUTIQUE , 

        																											ROLE.ROLE_TECNICO, 
        																											ROLE.ROLE_LIMPIEZA, 
        																											ROLE.ROLE_CONSERVACION, 

        																											ROLE.ROLE_RESPONSABLE_TECNICOS, 
        																											ROLE.ROLE_RESPONSABLE_LIMPIEZA, 

        																											ROLE.ROLE_RESPONSABLE_OPERACION,

        																											ROLE.ROLE_RECURSOS_HUMANOS,

        																											ROLE.ROLE_CONTABILIDAD,

        																											ROLE.ROLE_RESPONSABLE_PRESUPUESTO,

        																											ROLE.ROLE_OBRA,
        																											ROLE.ROLE_SISTEMAS,
        																											ROLE.ROLE_RECURSOS_MATERIALES,

        																											ROLE.ROLE_ATENCION_CLIENTES,


        																											ROLE.ROLE_RECURSOS_MATERIALES,


        																											ROLE.ROLE_MARKETING,
        																											ROLE.ROLE_RECLUTAMIENTO,
        																											ROLE.ROLE_JEFE_ADMIN,

        																											ROLE.ROLE_COMPROBACIONES,

        																											ROLE.ROLE_COMPRAS,

																															ROLE.RESIDENTE_OBRA,
																															ROLE.AUXILIAR_OBRA,
																															ROLE.ROLE_OBRA,
																															ROLE.ROLE_SHEI,


        																										]} component={Profile} />} />

        <Route path="faqs" element={<PrivateRoute roles={[
        																											ROLE.ADMIN, 

        																											ROLE.ROLE_CLIENTE, 
        																											ROLE.ROLE_BOUTIQUE , 

        																											ROLE.ROLE_TECNICO, 
        																											ROLE.ROLE_LIMPIEZA, 
        																											ROLE.ROLE_CONSERVACION, 

        																											ROLE.ROLE_RESPONSABLE_TECNICOS, 
        																											ROLE.ROLE_RESPONSABLE_LIMPIEZA, 

        																											ROLE.ROLE_RESPONSABLE_OPERACION,

        																											ROLE.ROLE_RECURSOS_HUMANOS,

        																											ROLE.ROLE_CONTABILIDAD,

        																											ROLE.ROLE_RESPONSABLE_PRESUPUESTO,

        																											ROLE.ROLE_OBRA,
        																											ROLE.ROLE_SISTEMAS,
        																											ROLE.ROLE_RECURSOS_MATERIALES,

        																											ROLE.ROLE_ATENCION_CLIENTES,


        																											ROLE.ROLE_RECURSOS_MATERIALES,


        																											ROLE.ROLE_MARKETING,
        																											ROLE.ROLE_RECLUTAMIENTO,
        																											ROLE.ROLE_JEFE_ADMIN,

        																											ROLE.ROLE_COMPROBACIONES,

        																											ROLE.ROLE_COMPRAS,

																															ROLE.RESIDENTE_OBRA,
																															ROLE.AUXILIAR_OBRA,
																															ROLE.ROLE_OBRA,
																															ROLE.ROLE_SHEI,


        																										]} component={FAQ} />} />


        <Route path="administracion" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={Administracion} />} >
						<Route path="equipos_criticos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={EquiposCriticos} />} />        	
						<Route path="zonas" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={Zonas} />} />        	
						<Route path="subzonas" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={Subzonas} />} />        	
        </Route>











        <Route path="calendario/propuesta" element={<PrivateRoute roles={[ROLE.ROLE_CLIENTE, ROLE.ROLE_BOUTIQUE ]} component={CalendarioPreviewCliente} />} />
        <Route path="calendario/visitas" element={<PrivateRoute roles={[ROLE.ROLE_CLIENTE, ROLE.ROLE_BOUTIQUE ]} component={CalendarioServicioCliente} />} />



        <Route path="calendario" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_RESPONSABLE_OPERACION,ROLE.ROLE_RESPONSABLE_TECNICOS,ROLE.ROLE_RESPONSABLE_LIMPIEZA, ROLE.ROLE_JEFE_ADMIN ]} component={CalendarioFull} />} />
        <Route path="calendario_preview" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={CalendarioPreview} />} />





        <Route path="mi_calendario" element={<PrivateRoute roles={[ROLE.ROLE_TECNICO,ROLE.ROLE_LIMPIEZA,ROLE.ROLE_CONSERVACION ]} component={MiCalendario} />} />
        <Route path="mi_reporte" element={<PrivateRoute roles={[ROLE.ROLE_TECNICO,ROLE.ROLE_LIMPIEZA,ROLE.ROLE_CONSERVACION ]} component={ReporteTareas} />} />
        <Route path="tareas" element={<PrivateRoute roles={[ROLE.ROLE_TECNICO,ROLE.ROLE_LIMPIEZA,ROLE.ROLE_CONSERVACION ]} component={Tareas} />} />



        <Route path="tickets" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_ATENCION_CLIENTES, ROLE.ROLE_JEFE_ADMIN]} component={Tickets} />} />
        <Route path="reporte_servicios" element={<PrivateRoute roles={[ROLE.ROLE_RESPONSABLE_TECNICOS, ROLE.ROLE_RESPONSABLE_LIMPIEZA ]} component={Reporte_servicios} />} />

        <Route path="mis_tickets" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CLIENTE, ROLE.ROLE_BOUTIQUE , ROLE.ROLE_TECNICO, ROLE.ROLE_LIMPIEZA,ROLE.ROLE_CONSERVACION, ROLE.ROLE_RESPONSABLE_TECNICOS,ROLE.ROLE_RESPONSABLE_LIMPIEZA ]} component={MisTickets} />} />




        <Route path="proveedores" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={Proveedores} />} >
						<Route path="lista" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={ListaProveedores} />} />        	
						<Route path="articulos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN]} component={ProveedoresArticulos} />} />        	
        </Route>




        <Route path="operacion" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_RESPONSABLE_OPERACION,ROLE.ROLE_CEO,ROLE.ROLE_RESPONSABLE_LIMPIEZA,ROLE.ROLE_RESPONSABLE_TECNICOS, ROLE.ROLE_JEFE_ADMIN  ]} component={Operacion} />} />



        <Route path="operacion/presupuestos" element={<PrivateRoute roles={[ROLE.ROLE_RESPONSABLE_PRESUPUESTO,ROLE.ROLE_RESPONSABLE_LIMPIEZA,ROLE.ROLE_RESPONSABLE_TECNICOS,ROLE.ADMIN  ]} component={Presupuestos} />} />
        <Route path="administracion/presupuestos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_JEFE_ADMIN ]} component={PresupuestosAdministracion} />} />
        <Route path="tecnico/presupuestos" element={<PrivateRoute roles={[ROLE.ROLE_TECNICO, ROLE.ROLE_RESPONSABLE_TECNICOS, ROLE.ROLE_RESPONSABLE_LIMPIEZA ]} component={PresupuestosTecnico} />} />
        <Route path="cliente/presupuestos" element={<PrivateRoute roles={[ROLE.ROLE_BOUTIQUE, ROLE.ROLE_CLIENTE ]} component={PresupuestosBoutiques} />} />

        <Route path="cliente/refaccionamiento" element={<PrivateRoute roles={[ROLE.ROLE_BOUTIQUE ]} component={Refaccionamiento} />} />















        <Route path="cobranza" element={<PrivateRoute roles={[ROLE.ADMIN ]} component={Cobranza} />} />
        <Route path="pagos" element={<PrivateRoute roles={[ROLE.ADMIN ]} component={Pagos} />} />


        <Route path="contabilidad" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_CONTABILIDAD, ROLE.ROLE_JEFE_ADMIN ]} component={Contabilidad} />} />
        <Route path="cobro_servicios" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_CONTABILIDAD, ROLE.ROLE_JEFE_ADMIN]} component={CobranzaServicios} />} />






        <Route path="recursos_humanos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_RECURSOS_HUMANOS, ROLE.ROLE_RECLUTAMIENTO, ROLE.ROLE_JEFE_ADMIN ]} component={RecursosHumanos} />} />

        <Route path="incidencias" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_RECURSOS_HUMANOS, ROLE.ROLE_RECLUTAMIENTO]} component={Incidencias} />} />






        <Route path="mis_requisiciones" element={<PrivateRoute roles={[

        																											ROLE.ADMIN, 

        																											ROLE.ROLE_RESPONSABLE_TECNICOS, 
        																											ROLE.ROLE_RESPONSABLE_LIMPIEZA, 

        																											ROLE.ROLE_RESPONSABLE_OPERACION,

        																											ROLE.ROLE_RECURSOS_HUMANOS,

        																											ROLE.ROLE_CONTABILIDAD,

        																											ROLE.ROLE_RESPONSABLE_PRESUPUESTO,

        																											ROLE.ROLE_SISTEMAS,
        																											ROLE.ROLE_RECURSOS_MATERIALES,

        																											ROLE.ROLE_ATENCION_CLIENTES,


        																											ROLE.ROLE_MARKETING,
        																											ROLE.ROLE_RECLUTAMIENTO,
        																											ROLE.ROLE_JEFE_ADMIN,

        																											ROLE.ROLE_COMPROBACIONES,

        																											ROLE.ROLE_COMPRAS,


																															ROLE.RESIDENTE_OBRA,
																															ROLE.AUXILIAR_OBRA,
																															// ROLE.ROLE_OBRA,
																															ROLE.ROLE_SHEI,



        	]} component={MisRequisiciones} />} />


        <Route path="requisiciones" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_RESPONSABLE_OPERACION, ROLE.RESIDENTE_OBRA ]} component={Requisiciones} />} />

        <Route path="comprobaciones_egresos" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_COMPROBACIONES ]} component={ComprobacionesEgresos} />} />

        <Route path="deposito_egresos" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROLE_CONTABILIDAD ]} component={PagoRequisiciones} />} />
        <Route path="programacion_pagos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_JEFE_ADMIN, ROLE.ROLE_CONTABILIDAD ]} component={ProgramacionPagos} />} />


        <Route path="compras" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROLE_COMPRAS ]} component={ComprasRequisiciones} />} />







        <Route path="vehiculos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_JEFE_ADMIN ]} component={Vehiculos} />} />






        <Route path="reportes_analiticas" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_RESPONSABLE_TECNICOS, ROLE.ROLE_RESPONSABLE_LIMPIEZA, ROLE.ROLE_RESPONSABLE_OPERACION, ROLE.ROLE_JEFE_ADMIN  ]} component={Reportes_analiticas} />} />






        <Route path="inventario" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO, ROLE.ROLE_RECURSOS_MATERIALES, ROLE.ROLE_JEFE_ADMIN ]} component={Inventario} />} />


        <Route path="mis_herramientas" element={<PrivateRoute roles={[
        																											ROLE.ADMIN, 

        																											ROLE.ROLE_TECNICO, 
        																											ROLE.ROLE_LIMPIEZA, 
        																											ROLE.ROLE_CONSERVACION, 

        																											ROLE.ROLE_RESPONSABLE_TECNICOS, 
        																											ROLE.ROLE_RESPONSABLE_LIMPIEZA, 

        																											ROLE.ROLE_RESPONSABLE_OPERACION,

        																											ROLE.ROLE_RECURSOS_HUMANOS,

        																											ROLE.ROLE_CONTABILIDAD,

        																											ROLE.ROLE_RESPONSABLE_PRESUPUESTO,

        																											ROLE.ROLE_OBRA,
        																											ROLE.ROLE_SISTEMAS,
        																											ROLE.ROLE_RECURSOS_MATERIALES,

        																											ROLE.ROLE_ATENCION_CLIENTES,


        																											ROLE.ROLE_MARKETING,
        																											ROLE.ROLE_RECLUTAMIENTO,
        																											ROLE.ROLE_JEFE_ADMIN,

        																											ROLE.ROLE_COMPROBACIONES,

        																											ROLE.ROLE_COMPRAS,


																															ROLE.RESIDENTE_OBRA,
																															ROLE.AUXILIAR_OBRA,
																															ROLE.ROLE_OBRA,
																															ROLE.ROLE_SHEI,

        	]} component={Herramientas} />} />




        <Route path="corporativo_clientes" element={<PrivateRoute roles={[
        																											ROLE.ADMIN, ROLE.ROLE_CEO,

        																											ROLE.ROLE_TECNICO, 
        																											ROLE.ROLE_LIMPIEZA, 
        																											ROLE.ROLE_CONSERVACION, 

        																											ROLE.ROLE_RESPONSABLE_TECNICOS, 
        																											ROLE.ROLE_RESPONSABLE_LIMPIEZA, 

        																											ROLE.ROLE_RESPONSABLE_OPERACION,

        																											ROLE.ROLE_RECURSOS_HUMANOS,

        																											ROLE.ROLE_CONTABILIDAD,

        																											ROLE.ROLE_RESPONSABLE_PRESUPUESTO,

        																											ROLE.ROLE_OBRA,
        																											ROLE.ROLE_SISTEMAS,
        																											ROLE.ROLE_RECURSOS_MATERIALES,

        																											ROLE.ROLE_ATENCION_CLIENTES,


        																											ROLE.ROLE_MARKETING,
        																											ROLE.ROLE_RECLUTAMIENTO,
        																											ROLE.ROLE_JEFE_ADMIN,

        																											ROLE.ROLE_COMPROBACIONES,

        																											ROLE.ROLE_COMPRAS,

																															ROLE.RESIDENTE_OBRA,
																															ROLE.AUXILIAR_OBRA,
																															ROLE.ROLE_OBRA,
																															ROLE.ROLE_SHEI,



        	]} component={CorporativoClientes} />} />









        {
        // <Route path="operacion/mantenimiento" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_RESPONSABLE_OPERACION ]} component={Mantenimiento} />} />
        // <Route path="operacion/limpieza" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_RESPONSABLE_OPERACION ]} component={Limpieza} />} />        	
        }




        <Route path="clientes" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_RESPONSABLE_OPERACION, ROLE.ROLE_CEO, ROLE.ROLE_RESPONSABLE_PRESUPUESTO, ROLE.ROLE_JEFE_ADMIN ]} component={Clientes} />} />
        <Route path="cliente/:uuid_cliente" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CEO,  ROLE.ROLE_RESPONSABLE_OPERACION, ROLE.ROLE_RESPONSABLE_PRESUPUESTO ]} component={Cliente} />} />


        
        <Route path="mi_panel" element={<PrivateRoute roles={[ROLE.ROLE_CLIENTE,ROLE.ROLE_BOUTIQUE ]} component={PanelCliente} />} />



        <Route path="circulares" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_CLIENTE,ROLE.ROLE_BOUTIQUE, ROLE.ROLE_JEFE_ADMIN ]} component={Circulares} />} />





        <Route path="verify/:uuid_usuario" element={<PrivateRoute roles={[ROLE.ROLE_CLIENTE,ROLE.ROLE_BOUTIQUE ]} component={Verificacion} />} />


      </Routes>

    </>
  )
}

export default App
